import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link, generatePath } from 'react-router-dom'
import { API, Router, Store } from 'app'
import { DefaultLayout } from 'view/component/Layout'
import { Form } from 'react-bootstrap'

export const AdminHomePage = () => {
	const [selectedFile, setSelectedFile] = useState(null)
	const handleFileChange = event => {
		setSelectedFile(event.target.files[0])
	} 
	const handleUpload = async () => {
		const formData = new FormData()
		formData.append('imgFile', selectedFile, selectedFile.name)
		try {
			const payload = await API.methods.ADMIN_IMAGE_UPLOAD(formData)
			Store.actions.FLASH_ADD.dispatch({type:'SUCCESS', 'message':`File saved with _id ${payload.image._id}`})			
		} catch (error) {
			console.log(error)
			Store.actions.FLASH_ADD.dispatch({type:'WARNING', 'message':`Unable to upload file`})
		}
	}

	const handleCacheInvalidate = async () => {
		Store.actions.FLASH_ADD.dispatch({type:'INFO', 'message':'Attempting cache invalidation'})
		try {
			// const payload = 
			await API.methods.ADMIN_CACHE_INVALIDATE({})
			Store.actions.FLASH_ADD.dispatch({type:'SUCCESS', 'message':'Cache invalidated'})
		} catch (error) {
			console.log(error)
			Store.actions.FLASH_ADD.dispatch({type:'WARNING', 'message':'Unable to invalidate cache'})
		}
	}

	const handleCacheUpdate = async () => {
		Store.actions.FLASH_ADD.dispatch({type:'INFO', 'message':'Attempting cache updation'})
		try {
			// const payload =
			await API.methods.ADMIN_CACHE_UPDATE({})
			Store.actions.FLASH_ADD.dispatch({type:'SUCCESS', 'message':'Cache updated'})
		} catch (error) {
			console.log(error)
			Store.actions.FLASH_ADD.dispatch({type:'WARNING', 'message':'Unable to update cache'})
		}
	}

	const handleCacheRebuild = async () => {
		Store.actions.FLASH_ADD.dispatch({type:'INFO', 'message':'Attempting cache rebuild'})
		try {
			// const payload = 
			await API.methods.ADMIN_CACHE_REBUILD({})
			Store.actions.FLASH_ADD.dispatch({type:'SUCCESS', 'message':'Cache rebuilt'})
		} catch (error) {
			console.log(error)
			Store.actions.FLASH_ADD.dispatch({type:'WARNING', 'message':'Unable to rebuild cache'})
		}
	}

	return <DefaultLayout>
		<h4>Admin Home</h4>
        <Link to={Router.routes.ADMIN_USERS_MANAGE.path}>Manage users</Link><br />
		<Link to={generatePath(Router.routes.ADMIN_COURSE_MANAGE.path, {course: 'prmoPractice'})}>Manage PRMO Practice course</Link><br />
        <Button onClick={handleCacheInvalidate}>Invalidate cache</Button> | 
        <Button onClick={handleCacheUpdate}>Update cache</Button> | 
        <Button onClick={handleCacheRebuild}>Rebuild cache</Button>
		<br />
		<Card>
			<Card.Header>Upload an image</Card.Header>
			<Card.Body>
				<Form>
					<Form.File id="imgFile" onChange={handleFileChange}/>
					<Button onClick={() => handleUpload()}>Upload</Button>
				</Form>
			</Card.Body>
		</Card>
		
	</DefaultLayout>
}

