import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { Router } from 'app'
import { BackCrumbs } from 'view/component/Util'
import { DefaultLayout } from 'view/component/Layout'
import { TermsContent } from './TermsPage'
import 'style/page/Index/indexPage.css'

export const PaymentPage = () => 
	<DefaultLayout>
		<BackCrumbs path={Router.routes.COURSES.path} msg='Back to all courses' />
        <h4>How to subscribe</h4>
        <ol>
            <li>For a limited duration, the <Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'prmoPractice'} )}>PRMO and RMO Question bank</Link> is <b>available for free</b>!<br />
            Simply sign in using a Google account, to access the course material.</li>
            <li>Subscription fee for accessing <Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'pradnyaSolutions'} )}>Pradnya paper solutions</Link> is <b>Rs 400 only</b>.<br />
            Fee for accessing <Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'prabhutvaSolutions'} )}>Prabhutva paper solutions</Link> is <b>Rs 400 only</b>.</li>
            <li>Please pay the subscription fee for the paper solutions of any one exam (Rs 400) or both exams (total Rs 800); by Online transfer or PayTM / Google Pay / UPI to the following account:
            <Card>
                <Card.Body>
                    <pre>
                        Name: <b>Sunil Madhukar Sohani</b><br />
                        Bank: <b>ICICI Bank</b><br />
                        IFSC code:<b>ICIC0000038</b><br />
                        Account No: <b>003801000911</b><br />
                        Branch: <b>Bandra (West), Mumbai</b><br />
                        Mobile number: <b>9820379104</b>
                    </pre>
                </Card.Body>
            </Card>
            </li>
            <li>After payment; kindly send us the following information by email to <a href='mailto:smsohani@gmail.com'>smsohani@gmail.com</a>:<br />
                <ul>
                    <li>Transaction ID/screenshot</li>
                    <li>Name of the course(s) you want to access (Pradnya/Prabhutva)</li>
                    <li>Google login email address which you will use to sign in</li>
                </ul>
            </li>
            <li>Your subscription will be activated; and you will get a confirmation email for the same. After that, you can access all the question paper solutions for the course you subscribed to.</li>
        </ol>
        <TermsContent />
	</DefaultLayout>