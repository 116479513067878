var default_state = {
    app: {
        debug: false,
        theme: 'light',
        title: '',
        version:{
            apiVersion: null,
            apiBuildDate: null,
            webVersion: process.env.REACT_APP_WEB_GIT_TAG,
            webBuildDate: process.env.REACT_APP_WEB_BUILD_DATE,
        }
    },
    auth: {
        user: null,
        status: 'LOADING'
    },
    coursesInfo: null,
    flash: {
        lastKey: -1,
        messages: [],
    }
}
    
export const createState = () => default_state