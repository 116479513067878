import React from 'react'
import { Nav, Navbar, Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Router } from 'app'
// import { Footer } from './Element/Footer'
import { NavbarRight } from './Element/NavbarRight'
import { FlashBoard } from 'view/component/Layout/Element/FlashBoard'
import 'style/component/layout/defaultLayout.css'

export const NarrowLayout = (props) => 
	<>		
		<Navbar bg='dark' variant='dark' expand='md' sticky='top'>
			<Navbar.Brand className='header-title'>
				<Link to={Router.routes.INDEX.path} style={{fontVariantCaps:'small-caps'}}>
					Sohani Academy
				</Link>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />	
			<Navbar.Collapse id="responsive-navbar-nav">
			<Nav className='mr-auto'>
				<Nav.Link as={Link} to={Router.routes.ABOUT.path}>
					About us
				</Nav.Link>&nbsp;&nbsp;
				<Nav.Link as={Link} to={Router.routes.CONTACT.path}>
					Contact us
				</Nav.Link>
			</Nav>
			<NavbarRight />
			</Navbar.Collapse>
		</Navbar>
		<Container fluid className='container-main'>
			<Row>
				<Col md={{ span: 8, offset: 2}}>
					<FlashBoard />
					{ props.children }
					<div style={{'minHeight':'25vh'}}></div>
				</Col>
			</Row>
		</Container>
		{/* <Row>
			<Col>
				<Footer />
			</Col>
		</Row> */}
	</>