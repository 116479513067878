import React from 'react'
import { useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom'
import { Store } from 'app/base'
import { Auth } from './Auth'
import { LoadingPage, NotFoundPage, AccessDeniedPage } from 'view/page/Error'

// actual path imports
import { IndexPage, AboutPage, CoursesPage, MathFoundationsPage, WorkshopsPage, PaymentPage, ContactPage, CreditsPage, TermsPage, GeneratePage } from 'view/page/Index'
import { UserHomePage, UserSettingsPage, UserSubscriptionsPage } from 'view/page/User'
import { CoursePage } from 'view/page/Course'
import { DocumentViewPage } from 'view/page/Document'
import { AdminHomePage, UserMgmtPage, CourseMgmtPage } from 'view/page/Admin'

const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}

const TitleSetter = (props) => {
    useEffect(() => {
        if (props.title){
            document.title = `${props.title} | Sohani Academy`
        } else {
            document.title = `Sohani Academy`
        }
    }, [props.title])
    return props.children
}
export class Router {
    static routes = {
        INDEX: { path: '/', title: 'Home', component: IndexPage },
        ABOUT: { path: '/about', title: 'About us', component: AboutPage },
        COURSES: { path: '/courses', title: 'Courses', component: CoursesPage },
        WORKSHOPS: { path: '/workshops', title: 'Workshops', component: WorkshopsPage },
        MATH_FOUNDATIONS: { path: '/math-foundations', title: 'Workshops', component: MathFoundationsPage },
        PAYMENT: { path: '/subscribe', title: 'Subscription', component: PaymentPage },
        CONTACT: { path: '/contact', title: 'Contact us', component: ContactPage },
        CREDITS: { path: '/credits', title: 'Credits', component: CreditsPage },
        TERMS: { path: '/terms', title: 'Terms and conditions', component: TermsPage },
        COURSE_VIEW: { path: '/course/:course', title: 'Course', component: CoursePage },
        COURSE_DOCUMENT_VIEW: { path: '/course/:course/document/:id', title: 'Course', component: DocumentViewPage },
        USER_HOME: { path: '/user', role: 'ROLE_USER', title: 'User home', component: UserHomePage },
        USER_SETTINGS: { path: '/user/settings', role: 'ROLE_USER', title: 'User settings', component: UserSettingsPage },
        USER_SUBSCRIPTIONS: { path: '/user/subscriptions', role: 'ROLE_USER', title: 'User subscriptions', component: UserSubscriptionsPage },
        ADMIN_HOME: { path: '/admin', role: 'ROLE_ADMIN', title: 'Admin home', component: AdminHomePage },
        ADMIN_USERS_MANAGE: { path: '/admin/manage/users', role: 'ROLE_ADMIN', title: 'Admin user management', component: UserMgmtPage },
        ADMIN_COURSE_MANAGE: { path: '/admin/manage/course/:course', role: 'ROLE_ADMIN', title: 'Admin course management', component: CourseMgmtPage },
        GENERATE_HOME: { path: '/generate', title: 'Generate', component: GeneratePage },
        GENERATE_VIEW: { path: '/generate/:pattern', title: 'Generate', component: GeneratePage }
    }
    
    static RouterMap = () => {
        const auth = Store.useSelector(state => state.auth)
        var retVal = []
        for (var key in Router.routes){
            const node = Router.routes[key]
            const role = node.role || 'ROLE_ANON'
            const title = node.title
            let component
            if (role === 'ROLE_ANON'){
                component = node.component || null
            } else if (!auth.user){
                component = LoadingPage
            } else if (!Auth.checkAccess(auth, role)){
                component = AccessDeniedPage
            } else {
                component = node.component || null
            }
            //retVal.push(<Route key={key} path={node.path || ''} component={component} exact={true} />)
            retVal.push(<Route exact={true} key={key} path={node.path || ''} render={
                props=><TitleSetter title={title} >{React.createElement(component, props)}</TitleSetter>
            } />)
        }
        return <BrowserRouter>
            <ScrollToTop />
            <Switch>
                { retVal }
                <Route component={NotFoundPage} />
            </Switch>
        </BrowserRouter>
    }
}

