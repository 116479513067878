import React from 'react'
import { DocumentPdfView } from './DocumentPdfView'
import { DocumentLinkView } from './DocumentLinkView'

export const DocumentView = (props) => {
  if (props.type === 'pdf'){
    return <DocumentPdfView {...props} />
  } else if (props.type === 'link'){
    return <DocumentLinkView {...props} />
  }
}