import React, { useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { API } from 'app'
import { useDataLoader } from 'view/component/Util'
import { DefaultLayout } from 'view/component/Layout'
import { WorksheetMgmtView } from './WorksheetMgmtView'

const CourseMgmtView = (props) => {
	const [curWorksheet, setCurWorksheet] = useState(-1)
	const data = props.data

	const setWorksheetContent = (content) => {
		var resources = [...data.resources]
		resources[curWorksheet].document.content = content
		props.setData({...data, resources})
	}

	if (curWorksheet >= 0){
		return <WorksheetMgmtView worksheet={data.resources[curWorksheet].document.content} 
			setWorksheetContent={setWorksheetContent} setCurWorksheet={setCurWorksheet} />
	} else {
		var worksheets = []
		for (let idx in data.resources){
			var res = data.resources[idx]
			worksheets.push(<tr key={res.document.name}>
				<td>
					{ res.document.name }
				</td>
				<td>
					{ new Date(res.document.content.releaseDate).toDateString() }
				</td>
				<td>
					{ res.document.content.contents.length}
				</td>
				<td>
					<Button size="sm" variant="link" onClick={() => setCurWorksheet(idx)}>Edit</Button>
				</td>
			</tr>)
		}
		return <>
			<h5>PRMO practice question bank</h5>
			<Table>
			<thead>
				<tr><th>Worksheet Name</th><th>Release Date</th><th>Number of problems</th><th>Actions</th></tr>
			</thead>
			<tbody>
				{ worksheets }
			</tbody>
			</Table>	
		</>
	}
}

export const CourseMgmtPage = (props) => {
	var params = props.match.params
	var courseName = params.course
	const {data, setData, viewData} = useDataLoader({
        name: 'course',
        loader: async () => await API.methods.ADMIN_COURSE_GET({name: courseName}),
        setter: payload => payload.course,
    })

	const render = () => <CourseMgmtView data={data} setData={setData} />

	return <DefaultLayout>
		{ viewData(render)}
	</DefaultLayout>
}