import {  createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { useSelector as reduxSelect } from 'react-redux'
import { createLogger } from 'redux-logger'
import promise from 'redux-promise-middleware'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'

import { createState } from './State'
import { Action, AppActions, AuthActions, CoursesInfoActions, FlashActions } from './Action'

const getMiddlewares = () => {
    var middlewares = [promise, thunk]
    if (process.env.NODE_ENV === 'development'){
        middlewares.push(createLogger({ diff: true, collapsed: (state, action) => true }))
    }
    return middlewares
}
const composeEnhancers = composeWithDevTools({})

export class Store {
    static actions = {
        APP_STATE_SET: new Action({type: 'APP_STATE_SET', reducer: AppActions.stateSet}),
        APP_DEBUG_SET: new Action({type: 'APP_DEBUG_SET', reducer: AppActions.debugSet}),
        APP_API_VERSION_SET: new Action({type: 'APP_API_VERSION_SET', reducer: AppActions.apiVersionSet}),
        APP_TITLE_SET: new Action({type: 'APP_TITLE_SET', reducer: AppActions.titleSet}),
        AUTH_UPDATE: new Action({type: 'AUTH_UPDATE', reducer: AuthActions.update}),
        COURSESINFO_UPDATE: new Action({type: 'COURSESINFO_UPDATE', reducer: CoursesInfoActions.update}),
        FLASH_ADD: new Action({type: 'FLASH_ADD', reducer: FlashActions.add}),
        FLASH_CLOSE_START: new Action({type: 'FLASH_CLOSE_START', reducer: FlashActions.closeStart}),
        FLASH_CLOSE_FINISH: new Action({type: 'FLASH_CLOSE_FINISH', reducer: FlashActions.closeFinish}),
    }

    static reduce = (state, action) => (action.type in Store.actions) ? 
            Object.assign({}, state, Store.actions[action.type]._reducer(state, action.data)) : state

    static _store = createStore(Store.reduce, createState(), composeEnhancers(applyMiddleware(...getMiddlewares())))

    static getStore = () => Store._store
    static dispatch = (type, data=null) => Store._store.dispatch({type, data})
    
    static useSelector = reduxSelect
    static init(){
        for(var type in Store.actions){
            Store.actions[type].setDispatcher(Store.dispatch)
        }
    }
}

Store.init()