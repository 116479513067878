/* global gapi */
import React from 'react'
import { Auth } from 'app'
import GoogleButton from 'img/Security/GoogleLogin/btn_google_signin_dark_normal_web@2x.png'

export class GoogleLogin extends React.Component {
    componentDidMount() {
        gapi.load('auth2', () => {
	    gapi.auth2.init({client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID})
                .then((authObj) => {
                    this.auth2 = authObj
                }, null)
        })
    }
    
    googleSignInClick = () => {
        if (this.auth2 !== undefined){
	        this.auth2.signIn().then((gUser) => 
                Auth.googleLogin(gUser.getAuthResponse().id_token))
        }
    }
        
    render(){
        return (
            <img src={GoogleButton} onClick={this.googleSignInClick}
                alt='Sign in with Google' style={{'height':'36px', width:'150px', 'marginTop':'0px'}} />
        )
    }
}