import React, { useState } from 'react'
import { Button, ListGroup, Modal, Form, Table } from 'react-bootstrap'
import { API, Logger, CoursesInfo, Store } from 'app'
import { useDataLoader } from 'view/component/Util'
import { DefaultLayout } from 'view/component/Layout'

const UserModal = (props) => {
	const subs = CoursesInfo.getSubs(props.user)

	const updateRoleToGold = async () => {
		try {
			const data = await API.methods.ADMIN_USER_UPDATE({action: 'UPDATE_ROLE_GOLD', email: props.user.email })
			props.updateUser(data)
			Store.actions.FLASH_ADD.dispatch({type: 'SUCCESS', message: 'User role updated'})
		} catch (err) {
			Store.actions.FLASH_ADD.dispatch({type: 'WARNING', message: 'User role update failed'})
            Logger.devLog('User activation failed', err)
        }
	}

	const updateRoleToUser = async () => {
		try {
			const data = await API.methods.ADMIN_USER_UPDATE({action: 'UPDATE_ROLE_USER', email: props.user.email })
			props.updateUser(data)
			Store.actions.FLASH_ADD.dispatch({type: 'SUCCESS', message: 'User role updated'})
		} catch (err) {
			Store.actions.FLASH_ADD.dispatch({type: 'WARNING', message: 'User role update failed'})
            Logger.devLog('User activation failed', err)
        }
	}

	const createSub = async (courseName) => {
		try {
			const data = await API.methods.ADMIN_USER_UPDATE({action: 'SUBSCRIBE', email: props.user.email, course: courseName })
			props.updateUser(data)
			Store.actions.FLASH_ADD.dispatch({type: 'SUCCESS', message: 'User subsciption updated'})
		} catch (err) {
			Store.actions.FLASH_ADD.dispatch({type: 'WARNING', message: 'User subscription failed'})
            Logger.devLog('User activation failed', err)
        }
	}

	const deleteSub = async (courseName) => {
		try {
			const data = await API.methods.ADMIN_USER_UPDATE({action: 'UNSUBSCRIBE', email: props.user.email, course: courseName })
			props.updateUser(data)
			Store.actions.FLASH_ADD.dispatch({type: 'SUCCESS', message: 'User subsciption updated'})
		} catch (err) {
			Store.actions.FLASH_ADD.dispatch({type: 'WARNING', message: 'User subscription failed'})
            Logger.devLog('User activation failed', err)
        }
	}

	var subOptions = []
	var allCourses = CoursesInfo.getData().courses
	for (let course_id in allCourses){
		let course_name = allCourses[course_id].name
		if ((course_id in subs) && (subs[course_id].status === 'ACTIVE')){
			subOptions.push(<ListGroup.Item key={course_id}>{ course_name }:&nbsp;
				<Button size='sm' variant='success' onClick={() => deleteSub(course_name)}>Delete Subscription</Button>
			</ListGroup.Item>)
		} else {
			subOptions.push(<ListGroup.Item key={course_id}>{ course_name }:&nbsp; 
				<Button size='sm' variant='primary' onClick={() => createSub(course_name)}>Create Subscription</Button>
			</ListGroup.Item>)
		}
	}

	return <Modal show={true} onHide={props.handleClose}>
		<Modal.Header closeButton>
			<Modal.Title>Manage user</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Button size='sm' variant='warning' onClick={() => updateRoleToGold()}>Update ROLE_GOLD</Button><br />
			<Button size='sm' variant='warning' onClick={() => updateRoleToUser()}>Update ROLE_USER</Button><br />
			Set the active subscriptions for { props.user.email }:
			<Form>
				<ListGroup>
				{ subOptions }
				</ListGroup>
			</Form>
		</Modal.Body>
	</Modal>
}

const UserSubs = (subs) => {
	var tags = []
	for(var course_id in subs){
		var course = CoursesInfo.getCourse(course_id)
		tags.push(<span key={course_id} >
			<b>{ course.name }: </b>
			{ subs[course_id].status }
			( { subs[course_id].endDate } )
			<br />
		</span>)
	}
	return <>{ tags }</>
}

export const UserMgmtPage = () => {
	const [currentUser, setCurrentUser] = useState(null)
    const {data, setData, viewData} = useDataLoader({
        name: 'users',
        loader: async () => await API.methods.ADMIN_USERS_GET(),
        setter: payload => payload
    })

	const updateUser = (userData) => {
		var newData = Object.assign({}, data)
		var index = newData.users.findIndex(el => el.email === userData.email)
		newData.users[index] = userData
		setData(newData)
	}

	const openModal = (i) => {
		setCurrentUser(i)
	}	
	const closeModal = (user) => {
		setCurrentUser(null)
	}

	const usersTable = () => {
		var userRows = []
		for (let i in data.users){
			let user = data.users[i]
			userRows.push(<tr key={user.email}>
				<td>{ parseInt(i)+1 }</td><td>{ user.idinfo.name }</td><td>{ user.email }</td><td>{ user.role }</td>
				<td>{ UserSubs(CoursesInfo.getSubs(user)) }</td><td><Button size='sm' variant='primary' onClick={() => openModal(i)}>Change</Button></td>
				</tr>)
		}
		return <Table>
				<thead>
					<tr>
						<th>Sr</th><th>Name</th><th>Email</th><th>Role</th><th>Subscriptions</th><th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{ userRows }
				</tbody>
			</Table>
	}

	return <DefaultLayout>
		{ viewData(usersTable) }
		{ currentUser? <UserModal user={data.users[currentUser]} handleClose={closeModal} updateUser={updateUser}></UserModal>:<></> }
	</DefaultLayout>
}