import React, { useState } from 'react'
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { API, Logger, Store } from 'app'

const EditModal = (props) => {
	const [value, setValue] = useState(props.value)
	const handleChange = (e) => setValue(e.target.value)
	const handleClose = () => {
		props.setField(props.field, value)
		props.handleCloseModal()
	}
	return <Modal show={props.show} onHide={handleClose}>
		<Modal.Header>Editing { props.name }</Modal.Header>
		<Modal.Body>
			<Form>
				<Form.Group>					
					<Form.Control as="textarea" rows="3" value={value} onChange={handleChange} />
				</Form.Group>
			</Form>
		</Modal.Body>
	</Modal>
}

const EditableField = (props) => {
	const [editing, setEditing] = useState(false)
	const handleCloseModal = () => {
		setEditing(false)
	}
	const clickToEditTooltip = (props) => <Tooltip id="button-tooltip" {...props}>
		Click to edit
	</Tooltip>
	
	return <>
		<div onClick={() => setEditing(true)} style={{cursor:'pointer'}}>
			<OverlayTrigger placement="left" overlay={clickToEditTooltip}>
				<span>
					<h5>{ props.name }</h5>
					<div >{ props.value }</div>
				</span>
			</OverlayTrigger>
		</div>
		<hr />
		<EditModal show={editing} name={props.name} field={props.field} value={props.value}
			 setField={props.setField} handleCloseModal={handleCloseModal} />
	</>
}

export const ProblemMgmtView = (props) => {
	const [problem, setProblem] = useState(props.problem)
	const setField = (field, value) => {
		setProblem({...problem, [field]: value})
	}
	const save = async () => {
		try {
			const payload = await API.methods.ADMIN_PROBLEM_UPDATE({_id: problem._id, problem})
			setProblem(payload.problem)
			//also need to propagate data change to parent
			props.setProblemData(problem)	
		} catch (err) {
			Store.actions.FLASH_ADD.dispatch({type: 'WARNING', message: 'Problem save failed'})
            Logger.devLog('Problem save failed', err)
		}
	}
	return <>
		<Button size="sm" variant="link" onClick={() => props.setCurProblem(-1)} >Back to worksheet</Button>
		<h5>Problem _id: { problem._id }</h5>
		<Button onClick={save}>Save</Button>
		<hr />
		<EditableField name="Question" field="question" value={ problem.question } setField={setField} />
		<EditableField name="Level" field="level" value={ problem.level } setField={setField} />
		<EditableField name="Topics" field="topics" value={ problem.topics } setField={setField} />
		<EditableField name="Basic hint" field="hint1" value={ problem.hint1 } setField={setField} />
		<EditableField name="Detailed hint" field="hint2" value={ problem.hint2 } setField={setField} />			
		<EditableField name="Answer" field="answer" value={ problem.answer } setField={setField} />
		<EditableField name="Solution" field="solution" value={ problem.solution } setField={setField} />
		<EditableField name="Remarks" field="remarks" value={ problem.remarks } setField={setField} />
	</>
}