import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { API, Store } from 'app'
import { useDataLoader, RoleChecker } from 'view/component/Util'

const CommentEditModal = (props) => {
	const [value, setValue] = useState(props.value)
    const handleChange = (e) => setValue(e.target.value)
    if (props.show) {
        return <Modal size='lg' show={props.show} onHide={props.closeModalWithoutSaving}>
		<Modal.Header>Add/edit comment</Modal.Header>
		<Modal.Body>
			<Form>
				<Form.Group>					
					<Form.Control as="textarea" rows="5" value={value} onChange={handleChange} />
				</Form.Group>
                <Button size='sm' variant="success" type="button" onClick={() => props.saveAndCloseModal(value)}>Submit</Button>
                <Button size='sm' variant="danger" type="button" onClick={props.closeModalWithoutSaving}>Cancel</Button>
			</Form>
		</Modal.Body>
	</Modal>
    } else {
        return <></>
    }
}

const CommentView = (props) => {
    const childComments = []
    for(var child of props.data.children){
        childComments.push(<CommentView key={child._id} level={props.level+1} data={child} addComment={props.addComment} />)
    }
    return <div style={(props.level > 0)?{padding:'5px',margin:'5px',borderLeft:'1px dotted gray'}:{}}>
        { (props.level > 0)?<div>
            <i style={{position:'absolute',right:'20px'}}>{ new Date(props.data.createdDate).toDateString() }</i>
            <h6>{ props.data.author.idinfo.name }</h6>
            <p style={{fontSize:'0.9em', paddingLeft:'10px'}}>{ props.data.content }</p>
            <RoleChecker role='ROLE_USER'>
                <Button size='sm' variant='link' onClick={() => props.addComment(props.data._id)}>Reply</Button>
            </RoleChecker> 
            <hr />
        </div>:<></>}
        { childComments }
    </div>
}

export const CommentStreamView = (props) => {
    const [editing, setEditing] = useState({active: false, operation:null, parentComment:null, value:''})
    const {data, fetchData, viewData} = useDataLoader({
        name: 'commentStream',
        loader: async () => await API.methods.COURSE_PROBLEM_COMMENTS_VIEW({_id: props.problemId}),
        setter: payload => payload.commentStream
    })
    const saveAndCloseModal = async (value) => { 
        // process comment data
        try {            
            await API.methods.COURSE_PROBLEM_COMMENTS_ADD({
                parentStream: data._id,
                parentComment: editing.parentComment,
                content: value
                })
            setEditing({
                active: false,
                operation: null,
                parentComment:null,
                value: ''
            })
            fetchData()
            Store.actions.FLASH_ADD.dispatch({type:'INFO',message:'Comment added'})    
        } catch (error) {
            console.log(error)
            Store.actions.FLASH_ADD.dispatch({type:'WARNING',message:'Unable to add comment'})    
        }
    }
    const closeModalWithoutSaving = async (value) => { 
        setEditing({
            active: false,
            operation: null,
            parentComment:null,
            value: ''
        })
    }
    const addComment = (parentComment) => {
        setEditing({
            active: true,
            operation: 'ADD',
            parentComment,
            value: ''
        })
    }
    const viewComments = () => {
        return <>
            <br />
            <CommentView level={0} data={data} addComment={addComment} />
            <CommentEditModal show={editing.active} 
                saveAndCloseModal={saveAndCloseModal} 
                closeModalWithoutSaving={closeModalWithoutSaving}
                value={editing.value} />
            <RoleChecker role='ROLE_USER'>
                <Button size='sm' onClick={() => addComment(null)}>Add a new comment</Button>
            </RoleChecker>
            <RoleChecker role='ROLE_USER' invert={true}>
                <Button size='sm' variant='success' disabled>Please sign in to add a comment</Button>
            </RoleChecker>
        </>
    }
    return viewData(viewComments)
}