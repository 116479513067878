import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { DefaultLayout } from 'view/component/Layout'
import { BackCrumbs } from 'view/component/Util'
import { Router } from 'app'
import { SolutionsContentBase } from 'view/page/Course/Common/SolutionsPageBase'

const IPM2SolutionsContent = () => 
    <SolutionsContentBase 
        courseName='ipm2'
        courseTitle={<>IPM Megafinal Std II paper solutions</>}
        courseDescription={<></>}
        courseGoldHeader={<>Complete set of paper solutions for <b>Rs 400/- only</b></>}
    />

const IPM3SolutionsContent = () => 
    <SolutionsContentBase 
        courseName='ipm3'
        courseTitle={<>IPM Megafinal Std III paper solutions</>}
        courseDescription={<></>}
        courseGoldHeader={<>Complete set of paper solutions for <b>Rs 400/- only</b></>}
    />

const IPM4SolutionsContent = () => 
    <SolutionsContentBase 
        courseName='ipm4'
        courseTitle={<>IPM Megafinal Std IV paper solutions</>}
        courseDescription={<></>}
        courseGoldHeader={<>Complete set of paper solutions for <b>Rs 400/- only</b></>}
    />

    const IPM5SolutionsContent = () => 
    <SolutionsContentBase 
        courseName='ipm5'
        courseTitle={<>IPM Megafinal Std V paper solutions</>}
        courseDescription={<></>}
        courseGoldHeader={<>Complete set of paper solutions for <b>Rs 400/- only</b></>}
    />

export const IPMMegafinalSolutionsPage = () => 
    <DefaultLayout>
        <BackCrumbs path={Router.routes.INDEX.path} msg='Back to homepage' />
        <Tabs defaultActiveKey="#ipm2" id="uncontrolled-tab-example">
            <Tab eventKey="#ipm2" title="Std II">
                <IPM2SolutionsContent />
            </Tab>
            <Tab eventKey="#ipm3" title="Std III">
                <IPM3SolutionsContent />                
            </Tab>
            <Tab eventKey="#ipm4" title="Std IV">
                <IPM4SolutionsContent />                
            </Tab>
            <Tab eventKey="#ipm5" title="Std V">
                <IPM5SolutionsContent />                
            </Tab>
        </Tabs>
    </DefaultLayout>
