import React, { useState } from 'react'
import { Button, Card, Collapse } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Auth, Router } from 'app'
import { GoogleLogin } from 'view/component/Security/GoogleLogin'
import 'style/component/layout/element/header/navbarRight.css'

const UserPanel = (props) => 
  <Card id='navbar-user-card' className={props.className}>
    <Card.Body>
      <Card.Title>{ props.user.idinfo.name }</Card.Title>
      <Card.Subtitle>{ props.user.email }</Card.Subtitle>
      {/* <img src={ props.user.idinfo.picture } alt='Display'/> */}
      <hr />
      <span className='options'>
        { props.showAuth?<><Button variant='link'><Link to={Router.routes.ADMIN_HOME.path} >Admin</Link></Button>&#x2024;</>:<></> }
        <Button variant='link'><Link to={Router.routes.CONTACT.path} >Help</Link></Button>&#x2024;
        <Button variant='link'><Link to={Router.routes.USER_SETTINGS.path} >Settings</Link></Button>&#x2024;
        <Button variant='link' onClick={Auth.logout}>Sign out</Button>
      </span>
    </Card.Body>
  </Card>

const AuthView = (props) => {
  const auth = useSelector(state => state.auth)
  const [panelVisible, setPanelVisible] = useState(false)
  const togglePanel = () => setPanelVisible(!panelVisible)

  if (!auth.user){
    switch(auth.status){
      case 'CONNECTION_FAILED':
        return <Button variant='danger' size='sm' disabled>Connection error</Button>
      case 'LOGIN_FAILED':
        return <Button variant='danger' size='sm' disabled>Login error</Button>
      case 'LOADING':
        return <Button variant='primary' size='sm'>Loading</Button>
      default:
        return <Button variant='danger' size='sm'>Error</Button>
    }
  } else if (Auth.isLoggedIn(auth)){
    return <>
      <Button variant='outline-light' size='sm' onClick={ togglePanel }>
      {/* <button id='navbar-right-btn' type='button' onClick={ togglePanel }> */}
        <img id='navbar-right-img' src={ auth.user.idinfo.picture } alt='Display'/>
        <span className='d-none d-sm-inline'>&nbsp;{ auth.user.idinfo.name }
        </span>
      </Button>
      <Collapse in={panelVisible}>
        <UserPanel user={auth.user} showAuth={Auth.checkAccess(auth, 'ROLE_ADMIN')} />
      </Collapse>
    </>
  } else {
    return <GoogleLogin/>
  }
}

export const NavbarRight = () => 
  <span className='nav navbar-nav navbar-right'>
    <AuthView />
  </span>