import React from 'react'
import { Button, Card, ListGroup } from 'react-bootstrap'
import { Link, generatePath } from 'react-router-dom'
import { API, Router, Store, Auth, CoursesInfo } from 'app'
import { BackCrumbs, useDataLoader } from 'view/component/Util'
import { DefaultLayout } from 'view/component/Layout'

export const SolutionsContentBase = (props) => {
    const auth = Store.useSelector(state => state.auth)
    const courseName = props.courseName
    const {data, viewData} = useDataLoader({
        name: 'course',
        loader: async () => await API.methods.COURSE_VIEW({name: courseName}),
        setter: payload => payload.course
    })

    const documentLists = () => {
        var freeList = []
        var goldList = []
        for (var i in data.resources){
            var resource = data.resources[i]
            var authStatus = Auth.getAccessInfo(auth, resource.role)
            var subStatus = CoursesInfo.checkSubscription(auth.user, courseName)
            var accessPanel
            if (((authStatus === 'OK') && (subStatus === 'OK')) || (resource.role === 'ROLE_ANON')){
                accessPanel = <Link to={generatePath(Router.routes.COURSE_DOCUMENT_VIEW.path, 
                    { course: courseName, id: i } )}><Button size='sm' variant='success'>Click to open</Button></Link>
            } else if (resource.role === 'ROLE_USER'){
                accessPanel = <Button size='sm' variant='success' disabled>Please sign in to view for free</Button>
            } else if (resource.role === 'ROLE_GOLD'){
                accessPanel = <Button size='sm' variant='outline-primary' disabled>Please subscribe to view</Button>
            }
            var item = <ListGroup.Item key={i}>{resource.document.name} &nbsp;&nbsp;&nbsp;{ accessPanel }</ListGroup.Item>
            if (resource.role === 'ROLE_GOLD'){
                goldList.push(item)
            } else {
                freeList.push(item)
            }
        }
        return {
            freeList: <ListGroup variant='flush'>{freeList}</ListGroup>,
            goldList: <ListGroup variant='flush'>{goldList}</ListGroup>,
        }
    }
    
    const courseContents = () => {
        var { freeList, goldList } = documentLists()

        var subscribeBtn
        if (props.courseName === 'creativeWorkshop'){
            subscribeBtn = <Link to={Router.routes.WORKSHOPS.path}>
            <Button size='sm' variant='primary'>Register now</Button></Link>
        } else if (props.courseName === 'mathFoundations'){
            subscribeBtn = <Link to={Router.routes.MATH_FOUNDATIONS.path}>
            <Button size='sm' variant='primary'>Register now</Button></Link>
        } else {
            subscribeBtn = <Link to={Router.routes.PAYMENT.path}>
            <Button size='sm' variant='primary'>Subscribe now</Button></Link>
        }
        return <>
            <Card>
                <Card.Header>Free resources</Card.Header>
                <Card.Body> { freeList } </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Header> { props.courseGoldHeader } &nbsp;&nbsp;&nbsp; { subscribeBtn }
                </Card.Header>
                <Card.Body> { goldList } </Card.Body>
            </Card>
        </>
    }

    return <>
        <h4>{ props.courseTitle }</h4>
        <p>
            { props.courseDescription }
        </p>
        { viewData(courseContents) }
    </>
}

export const SolutionsPageBase = (props) => {
    var backCrumbs
    if (props.courseName === 'creativeWorkshop'){
        backCrumbs = <BackCrumbs path={Router.routes.WORKSHOPS.path} msg='Back to main page' />
    } else if (props.courseName === 'mathFoundations'){
        backCrumbs = <BackCrumbs path={Router.routes.MATH_FOUNDATIONS.path} msg='Back to main page' />
    } else 
    {
        backCrumbs = <BackCrumbs path={Router.routes.INDEX.path} msg='Back to homepage' />
    }
    return <DefaultLayout>
        { backCrumbs }
        <SolutionsContentBase {...props} />
    </DefaultLayout>
}
