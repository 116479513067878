import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import { Row, Col, ListGroup, Table, Button } from 'react-bootstrap'
import { Router } from 'app'
import { BackCrumbs } from 'view/component/Util'
import { NarrowLayout } from 'view/component/Layout'
import UpiQR from 'img/Subscription/UpiQR.png'

export const WorkshopsPage = () =>
	<NarrowLayout>
		<BackCrumbs path={Router.routes.INDEX.path} msg='Back to homepage' />
        <WorkshopBanner />
		<WorkshopFAQ />
	</NarrowLayout>

const WorkshopBanner = () => 
    <>
		<Row>
			<Col md={12}>
				<h4><u>CREATIVE</u>: A 10-day workshop in mathematics and programming</h4>
                <h5>for students in Std VI to VIII</h5>
                {/* <div class="fb-like" data-href="https://sohaniacademy.org/workshops" data-width="" data-layout="standard" data-action="like" data-size="small" data-share="true"></div> */}
                <ul>
					<li>Get acquainted with the creative process that lies at the heart of mathematics.</li>
					<li>Learn how to use programming for building complex ideas from simple ones.</li>
                    <li>Realize the deep connection between mathematics, programming, and the power of human imagination,<br />
                        and their importance to address some of the biggest challenges of the past, present and future.
                    </li>
				</ul>
                <Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'creativeWorkshop' })}>
							<Button size='sm' variant='primary'>Browse the workshop resources</Button>
						</Link>
                <hr />
                <h6 style={{ 'fontVariantCaps': 'small-caps' }}><u>Workshop details</u></h6>
                <Table size="sm" style={{ whiteSpace: 'nowrap', width:'1%'}}>
                    <tbody>
                        <tr>
                            <td style={{ width:'20%'}}>Week 1 (Mathematics):</td><td>12 December to 16 December; 7 PM to 9 PM</td>
                        </tr>
                        <tr>
                            <td>Week 2 (Programming):</td><td>19 December to 23 December; 7 PM to 9 PM</td>
                        </tr>
                        <tr>
                            <td>Venue (<a href="https://goo.gl/maps/MwaNRsMgzpjv8fJ5A" target="_blank" rel="noopener noreferrer">Location on map</a>):</td><td>M Prakash Institute, 328 Siddharth Towers, G.A.Kulkarni Road, Kothrud, Pune 411038<br />
                            (Sessions will be conducted in hybrid mode; students may join remotely through Zoom.)</td>
                        </tr>
                        <tr>
                            <td>Registration fees:</td><td>&#8377;1250 for only one week; or &#8377;2500 for the entire workshop</td>
                        </tr>
                        <tr>
                            <td>Eligibility:</td><td>Students currently in Std VI to VIII</td>
                        </tr>
                        <tr>
                            <td><b>How to register:</b></td>
                            <td>
                                <ol>
                                    <li>Please submit the following <a href="https://forms.gle/oUtAppfUnFiMVPon9">registration form</a></li>
                                    <li>Kindly pay the registration fee through Gpay to <b>7798190145</b> or via UPI to <b>7798190145@upi</b>.
                                    <ul>
                                        <li>You can scan one of the following QR codes to make the payment (click to magnify):</li>
                                        <li><a href="/UpiQR.png" title='Click to open in a new tab' target='_blank' rel="noopener noreferrer"><img src={UpiQR} style={{width: 400, padding: 10}} alt='QR' /></a></li>
                                    </ul>
                                    </li>

                                    <li>Kindly send a screenshot of the transaction on Whatsapp to <b>7798190145</b>, along with your name and email address.</li>
                                </ol>
                                Also, please join the "Maths and Prog Workshops" WhatsApp group using the following <a href="https://chat.whatsapp.com/CPzdivWCG1QImuQqJy0U5D">invitation link</a>.
                            </td>
                        </tr>
                    </tbody>
                </Table>
			</Col>
		</Row>
    </>

const WorkshopFAQ = () =>
	<>
        <h6>Some commonly asked questions:</h6>
		<ListGroup>
            <ListGroup.Item>
                <b>Q: Who is the instructor for this workshop?</b><br />
                Prashant Sohani has designed and will be conducting this workshop.<br />
                Prashant is an alumnus of IIT Bombay, and is working at NVIDIA, Pune since 2012. A bronze medallist at the International Mathematical Olympiad 2008, he has been contributing at all stages of Mathematical Olympiads;
                and he has accumulated over 2000 hours of training experience over the last 12 years.<br />
                Please check our <Link to={Router.routes.ABOUT.path}>About us</Link> page for more information about his academic background and experience so far.
            </ListGroup.Item>
            <ListGroup.Item>
                <b>Q: What is the purpose of this workshop?</b><br />
                A: There are several closely-related goals that we would like to achieve with this workshop:
                <ul>
                <li>To demonstrate how learning mathematics (or indeed any subject) can be a creative process, and not an ardous task.</li>
                <li>To provide students with an opportunity to devise their own solutions for a given problem, as well as to construct entirely new problems</li>
                <li>To illustrate some common themes that we encounter in mathematics as well as programming, such as:
                    <ul>
                        <li>Logical reasoning to find the solution to a given problem</li>
                        <li>Expressing one's ideas by accurately using formal notation or syntax</li>
                        <li>Reusing our previous work, building upon the same to achieve more complex results</li>
                    </ul>
                    </li>
                </ul>
                In short, the purpose is to empower students to think of an idea, then explore and build upon it, and realize the joy of learning through this approach.<br />
                Considering the short duration of the workshop, the goal is only for us to get their thoughts flowing along these lines, and to see where it leads.
            </ListGroup.Item>
            <ListGroup.Item>
                <b>Q: What would be the content covered in the workshop?</b><br />
                A: The aim is not to cover a lot of advanced mathematics or programming; but only to use these as means to achieve the above goals.<br />
                <ul>
                    <li>With that in mind, the mathematics portion of the workshop will be based on several topics from their school syllabus, related to properties of numbers, arithmetic operations, place values, divisibility, basic counting problems, and so on.</li>
                    <li>Similarly, the programming workshop will introduce students with the basics of Python; and how to use functions, conditions and loops.</li>
                    <li>Students will learn how to apply mathematics and basic programming to solve many problems which may appear difficult at first.</li>
                </ul>
            </ListGroup.Item>
            <ListGroup.Item>
                <b>Q: Can we join the workshop remotely?</b><br />
                A: Ideally, the best way to benefit from the workshop is to attend it offline. We would strongly urge everybody to consider the same.<br />
                   However, it is understandable that this may not be feasible for some participants. So for their benefit, the workshop will be conducted in hybrid mode (by simultaneously hosting on Zoom); and the recordings will also be made available to watch later.
            </ListGroup.Item>
            <ListGroup.Item>
                <b>Q: Can we participate in only the first week or the second week of the workshop?</b><br />
                A: Again ideally, the best way to benefit from the workshop is to attend both weeks. This is because we are going to highlight many connections between 
                the topics in mathematics and programming that we plan to cover. Attending only one week of the workshop would be like watching only one innings of a cricket match!<br />
                However, ultimately each participant can decide whatever works best as per their own feasibility.
            </ListGroup.Item>
            <ListGroup.Item>
                <b>Q: What are the exact eligibility criteria to attend?</b><br />
                A: Since this is the first experiment of its kind that we are trying; the eligibility criteria are quite flexible. For the most part, any students from Std VI to VIII are welcome; and we even welcome any interested parents or teachers to participate.<br />
                The only requirement is that for any participants attending the programming sessions, they should bring their own laptop.<br />
                Participants should visit <a href="https://www.python.org/downloads/">Python downloads</a>; then download and install the latest Python version available for their operating system.
            </ListGroup.Item>
            <ListGroup.Item>
                <b>Q: Will there be more workshops like this in the future?</b><br />
                A: We would certainly like to conduct more workshops; however it depends on the outcome of this first attempt, and the number of students interested to participate thereafter.<br />
                You can stay updated about any future workshops being planned, by joining the "Maths and Prog Workshops" WhatsApp group (by following this <a href="https://chat.whatsapp.com/CPzdivWCG1QImuQqJy0U5D">invitation link</a>); or by simply visiting this page again.
            </ListGroup.Item>
        </ListGroup>
        In case of any points not covered above; please post the same on the WhatsApp group. Or, you can directly write to us at <a href="mailto:admin@sohaniacademy.org">admin@sohaniacademy.org</a> with any questions or suggestions.
	</>
