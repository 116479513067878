import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Router } from 'app'

export const ErrorHelper = (props) => {
    let location = useLocation();
    return <>
        <div className='text-monospace text-danger'>{ props.err }</div>
        <a href={location.pathname}>Try reloading the page</a>; or if the error persists,
        please <Link to = {Router.routes.CONTACT.path} >contact us</Link>.
    </>
}