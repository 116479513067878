import { API, Logger, Store } from 'app/base'

export class Auth {
    static subroles = { // map each role to all its children
	    'ROLE_SU':    ['ROLE_SU', 'ROLE_ADMIN', 'ROLE_GOLD', 'ROLE_USER', 'ROLE_ANON'],
	    'ROLE_ADMIN': ['ROLE_ADMIN', 'ROLE_GOLD', 'ROLE_USER', 'ROLE_ANON'],
	    'ROLE_GOLD':  ['ROLE_GOLD', 'ROLE_USER', 'ROLE_ANON'],
	    'ROLE_USER':  ['ROLE_USER', 'ROLE_ANON'],
        'ROLE_ANON':  ['ROLE_ANON'],
    }

    static init = (data) => {
        try {            
            Store.actions.AUTH_UPDATE.dispatch({user: data.user, status: 'CONNECTED' })
        } catch (err) {
            Logger.devLog('Auth init failed', err)
            Store.actions.AUTH_UPDATE.dispatch({user: null, status: 'CONNECTION_FAILED' })
        }
    }

    static isLoggedIn = (auth) => (auth.user !== null) && (auth.user.role !== 'ROLE_ANON')

    static googleLogin = async (token) => {
        try {
            const data = await API.methods.AUTH_GOOGLE_LOGIN({token})
            Store.actions.AUTH_UPDATE.dispatch({user: data.user, status: 'CONNECTED' })
        } catch (err) {
            Logger.devLog('Google Login failed', err)
            Store.actions.AUTH_UPDATE.dispatch({user: null, status: 'LOGIN_FAILED' })
        }
    }

    static logout = async () => {
        try {
            const data = await API.methods.AUTH_LOGOUT()
            Store.actions.AUTH_UPDATE.dispatch({user: data.user, status: 'CONNECTED' })
        } catch (err) {
            Logger.devLog('Logout failed', err)
        }
    }

    static checkAccess = (auth, role) => {
        var curr_role = (auth.user !== null)? auth.user.role : 'ROLE_ANON'
        return Auth.subroles[curr_role].includes(role)
    }

    static getAccessInfo = (auth, role) => { // return more info
        if (Auth.checkAccess(auth, role)){
            return 'OK'
        }
        var subroles = Auth.subroles[role]
        var curr_role = (auth.user !== null)? auth.user.role : 'ROLE_ANON'
        var index = subroles.findIndex(el => el===curr_role)
        return subroles[index-1]
    }
}
