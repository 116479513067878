import React, { useEffect, useState } from 'react'
import { Store, Logger } from 'app'
import { ErrorHelper } from './ErrorHelper'
import { LoadingSpinner } from './LoadingSpinner'

export const useDataLoader = (params) => {
    const auth = Store.useSelector(state => state.auth)
    const [data, setData] = useState(null)
    const [status, setStatus] = useState('LOADING')

    const fetchData = async () => {
        try {
            const payload = await params.loader()
            setData(params.setter(payload))
            setStatus('LOADED')
        } catch (err) {
            Logger.devLog(`Unable to load data for ${params.name}`, err)
            setStatus('ERROR')
        }
    }
    
    useEffect(() => {
        if (auth.status !== 'LOADING'){
            fetchData()
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    params.deps || [auth])
        
    const viewData = (renderFn) => 
        status === 'LOADING'?<div style={{textAlign:'center'}}><LoadingSpinner /></div>:
            status === 'LOADED'?(renderFn()):
                status === 'ERROR'?<ErrorHelper err='Error loading data' />:
                    <ErrorHelper err='Unknown error' />

    const ViewDataWrapper = (props) => 
    status === 'LOADING'?<div style={{textAlign:'center'}}><LoadingSpinner /></div>:
        status === 'LOADED'?props.children:
            status === 'ERROR'?<ErrorHelper err='Error loading data' />:
                <ErrorHelper err='Unknown error' />
    return {data, fetchData, setData, viewData, ViewDataWrapper}
}