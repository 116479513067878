import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import { Row, Col, ListGroup, Table, Button } from 'react-bootstrap'
import { Router } from 'app'
import { BackCrumbs } from 'view/component/Util'
import { NarrowLayout } from 'view/component/Layout'
import UpiQR from 'img/Subscription/UpiQR.png'

export const MathFoundationsPage = () =>
	<NarrowLayout>
		<BackCrumbs path={Router.routes.INDEX.path} msg='Back to homepage' />
        <MFBanner />
	</NarrowLayout>

const MFBanner = () => 
    <>
		<Row>
			<Col md={12}>
				<h3><u>Foundations of Mathematics</u></h3>
			</Col>
		</Row>
		<Row>
			<Col md={12}>
				<h5>Watch and learn:</h5>
			</Col>
		</Row>
		<Row style={{"display":"block", "overflow-x": "auto", "white-space": "nowrap"}}>
			<Col lg={4} style={{"display": "inline-block", "float": "none" }}>
			Module 1: <b>Prelude to Foundations</b><br />
			<iframe width="100%" src="https://www.youtube.com/embed/videoseries?list=PL14I_Agr0tMYtzPCzY0Yptm4YLH_7ZPCJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</Col>
			<Col lg={4} style={{"display": "inline-block", "float": "none" }}>
			Module 2: <b>Geometry of circles</b><br />
			<iframe width="100%" src="https://www.youtube.com/embed/videoseries?list=PL14I_Agr0tMYtzPCzY0Yptm4YLH_7ZPCJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</Col>
			<Col lg={4} style={{"display": "inline-block", "float": "none" }}>
			Module 3: <b>Geometric transformations</b><br />
			<iframe width="100%" src="https://www.youtube.com/embed/videoseries?list=PL14I_Agr0tMYtzPCzY0Yptm4YLH_7ZPCJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</Col>
			<Col lg={4} style={{"display": "inline-block", "float": "none" }}>
			Module 1: <b>Prelude to Foundations</b><br />
			<iframe width="100%" src="https://www.youtube.com/embed/videoseries?list=PL14I_Agr0tMYtzPCzY0Yptm4YLH_7ZPCJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</Col>
			<Col lg={4} style={{"display": "inline-block", "float": "none" }}>
			Module 2: <b>Geometry of circles</b><br />
			<iframe width="100%" src="https://www.youtube.com/embed/videoseries?list=PL14I_Agr0tMYtzPCzY0Yptm4YLH_7ZPCJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</Col>
			<Col lg={4} style={{"display": "inline-block", "float": "none" }}>
			Module 3: <b>Geometric transformations</b><br />
			<iframe width="100%" src="https://www.youtube.com/embed/videoseries?list=PL14I_Agr0tMYtzPCzY0Yptm4YLH_7ZPCJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</Col>
		</Row>
		<hr />
		<Row>
			<Col md={12}>
				<h5>Meet and discuss:</h5>
				<ul>
					<li>Weekly discussion sessions on Zoom every Sunday morning</li>
					<li>Sessions are free of cost; admission is based on qualification test scores</li>
					<li>Interested participants may kindly submit the following Google Form: link</li>
					<li>Previous session recordings are available for free on Youtube: link</li>
				</ul>
			</Col>
		</Row>
		<Row>
			<Col md={12}>
				<h5>Solve and improve:</h5>
				<ul>
					<li>PRMO questions bank for practice is available online; with new problems added each week</li>
					<li>Timed practice tests are offered once in a month</li>
				</ul>
			</Col>
		</Row>
    </>