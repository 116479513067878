import React, { useState, useEffect } from 'react'
import { BlankLayout } from 'view/component/Layout'
import { Worker } from 'html2pdf.js'

export const GeneratePageBase = (props) => {
    const [questionsData, setQuestionsData] = useState([])
    const num_questions = props.num_questions
    const title=props.title
    const pattern=props.pattern
    const genQuestion = props.genQuestion
    const formatQuestion = props.formatQuestion
    const formatAnswer = props.formatAnswer

    const genWorksheet = (num_questions) => {
        var data = []
        for (var i=0;i<num_questions;i++){
            data.push(genQuestion())
        }
        return data
    }

    const formatWorksheetQuestions = () => {               
        var questions = []
        for (var i=0;i<questionsData.length;i++){
            questions.push(formatQuestion(questionsData[i], i+1))
        }
        return questions
    }

    const formatWorksheetAnswers = () => {               
        var answers = []
        for (var i=0;i<questionsData.length;i++){
            answers.push(formatAnswer(questionsData[i], i+1))
        }
        return answers
    }

    useEffect(() => {
        setQuestionsData(genWorksheet(num_questions)) // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const generateQuestionsPDF = async () => {
        const element = document.getElementById("questions");
        var worker = new Worker()
        await worker.from(element).save(`${pattern}_questions.pdf`)
    }

    const generateAnswersPDF = async () => {
        const element = document.getElementById("answers");
        var worker = new Worker()
        await worker.from(element).save(`${pattern}_answers.pdf`)
    }

    return <BlankLayout>
        <button onClick={generateQuestionsPDF}>Download Questions PDF</button>
        <button onClick={generateAnswersPDF}>Download Answers PDF</button>

        <div id='questions'>
            <h3>{ title }</h3>
            { formatWorksheetQuestions() }
        </div>
        <div id='answers'>
        <h3>{ title }</h3>
            { formatWorksheetAnswers() }
        </div>

	</BlankLayout>
}