import React from 'react'
import { Link } from 'react-router-dom'
import { Router } from 'app'
import { DefaultLayout } from 'view/component/Layout'

export const UserHomePage = () =>
    <DefaultLayout>
        <UserMain />
    </DefaultLayout>

const UserMain = () =>
    <>
        <h2>Home</h2>
        <Link to={Router.routes.USER_SETTINGS.path}>Settings</Link>
    </>