class Action {
    constructor(props){
        this.type = props.type
        this._reducer = props.reducer
    }

    setDispatcher = (dispatcher) => {
        this._dispatcher = dispatcher
    }
    dispatch = (data) => {
        this._dispatcher(this.type, data)
    }
}

class AppActions {
    static stateSet = (state, payload) => payload
    static debugSet = (state, payload) => ({
        app: {
            ...state.app,
            debug: payload
        }
    })
    static apiVersionSet = (state, payload) => ({
        app: {
            ...state.app,
            version:{
                ...state.app.version,
                apiVersion: payload.apiVersion,
                apiBuildDate: payload.apiBuildDate    
            }
        }
    })
    static titleSet = (state, payload) => ({
        app: {
            ...state.app,
            title: payload
        }
    })
}

class AuthActions {
    static update = (state, payload) => ({
        auth: payload
    })
}

class CoursesInfoActions {
    static update = (state, payload) => ({
        coursesInfo: payload
    })
}

class FlashActions {
    static add = (state, payload) => {
        var messages = [...state.flash.messages]
        var lastKey = state.flash.lastKey + 1 // ensure a new unique key
        messages.push({
            key: lastKey, 
            type: payload.type, 
            message: payload.message, 
            state:'VISIBLE'})
        return {
            flash: { lastKey, messages }
        }
    }

    static closeStart = (state, payload) => {
        var messages = [...state.flash.messages]
        for (var i = 0; i < messages.length; i++){
            if (messages[i].key === payload){
                messages[i].state = 'FADING'
                break
            }
        }
        return {
            flash: { ...state.flash, messages }
        }
    }

    static closeFinish = (state, payload) => {
        var messages = [...state.flash.messages]
        for (var i = 0; i < messages.length; i++){
            if (messages[i].key === payload){
                messages.splice(i, 1)
                break
            }
        }
        return {
            flash: { ...state.flash, messages }
        }
    }
}

export { Action, AppActions, AuthActions, CoursesInfoActions, FlashActions }