import { Logger } from 'app/base'

export class CoursesInfo {
    static coursesData = null
    static init = (data) => {
        try {
            // Store.actions.COURSESINFO_UPDATE.dispatch(data)
            this.coursesData = data
        } catch (err) {
            Logger.devLog('CoursesInfo init failed', err)
        }
    }

    static getCourse = (course_id) => this.coursesData.courses[course_id]

    static getData = () => this.coursesData

    static isActive = (sub) => {
        var startDate = new Date(sub.startDate)
        var endDate = new Date(sub.endDate)
        var curDate = new Date()
        return (startDate <= curDate) && (curDate <= endDate)
    }

    static getSubs = (user) => {
        var subs = {}
        if('subscriptions' in user){
            for (var sub of user.subscriptions.data){
                if (this.isActive(sub)){
                    subs[sub.course] = {status: 'ACTIVE', startDate: sub.startDate, endDate: sub.endDate}
                }
                else
                {
                    subs[sub.course] = {status: 'INACTIVE', startDate: sub.startDate, endDate: sub.endDate}
                }
            }    
        }
        return subs
    }    
    static checkSubscription = (user, courseName) => {
        var subs = this.getSubs(user)
        for (var sub in subs){
            if((this.coursesData.courses[sub].name === courseName) && (subs[sub].status === 'ACTIVE')){
                return 'OK'
            }
        }
        return 'ERR'
    }
}
