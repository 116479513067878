import React from 'react'
import { DefaultLayout } from 'view/component/Layout'
import { LoadingSpinner } from 'view/component/Util'

export const LoadingPage = () => 
    <DefaultLayout>
        <div style={{'textAlign':'center'}}>
            <pre>Loading...</pre>
            <LoadingSpinner />
        </div>
    </DefaultLayout>