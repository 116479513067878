import React from 'react'
import { Link } from 'react-router-dom'
import { Router } from 'app'
import { SolutionsPageBase } from 'view/page/Course/Common/SolutionsPageBase'

export const CreativeWorkshopPage = () => 
    <SolutionsPageBase 
        courseName='creativeWorkshop'
        courseTitle={<>Creative Workshop</>}
        courseDescription={<>For more information, please visit the <Link to={Router.routes.WORKSHOPS.path}>workshop main page</Link></>}
        courseGoldHeader={<>Please register to access all the workshop content</>}
    />