import React from 'react'
import { Auth, Store } from 'app'

export const RoleChecker = (props) => {
    const auth = Store.useSelector(state => state.auth)
    const invert = props.invert || false
    if (invert ^ Auth.checkAccess(auth, props.role)){
        return props.children
    } else {
        return <></>
    }
}