import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { Main, Router, Store } from 'app'

export const Root = ({ store, routingConfig }) => (
  <Provider store={store}>
    <Main/>
    <Router.RouterMap />
  </Provider>
  )

ReactDOM.render(<Root store={Store.getStore()} />,
    document.getElementById('root'))
