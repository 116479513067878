import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Footer } from './Element/Footer'
import { FlashBoard } from 'view/component/Layout/Element/FlashBoard'

export const FluidFullLayout = (props) => 
	<>		
		<Container className='container-main'>
			<Row>
				<Col>
					<FlashBoard />
					{ props.children }
				</Col>
			</Row>
		</Container>
		<Row>
			<Col>
				<Footer />
			</Col>
		</Row>
	</>