import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import { Row, Col, Button, ListGroup } from 'react-bootstrap'
import { Router } from 'app'
import { DefaultLayout } from 'view/component/Layout'
import { AboutContent } from './AboutPage'
import 'style/page/Index/indexPage.css'

const AnnouncementsBanner = () =>
	<>
		<Row>
			<Col sm={{ span: 10, offset: 1}} md={{ span: 8, offset: 2 }} style={{ textAlign: 'center', backgroundColor: '#eeeeee', padding: '10px',
						border: '1px solid black', borderRadius: '10px' }}>
				<h5>Announcing for Std VI to VIII:</h5>
				<h4><u>CREATIVE</u>: A 10-day workshop in mathematics and programming!</h4>
				<Link to={Router.routes.WORKSHOPS.path}><Button size='sm' variant='success'>Find out more</Button></Link>
			</Col>
		</Row>
	</>

const OnlineBanner = () =>
	<div>
		<Row>
			<Col md={{ span: 12 }} style={{ textAlign: 'center' }}>
				<h4 style={{ 'fontVariantCaps': 'small-caps' }}>Online training for competitive exams</h4>
			</Col>
		</Row>
		<Row>
			<Col md={{ span: 5, offset: 1 }}>
				<ListGroup variant='flush'>
					<ListGroup.Item><h5>IOQM question bank</h5>
						<Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'prmoPractice' })}>
							<Button size='sm' variant='primary'>View this week's questions for free</Button>
						</Link>
						&nbsp;&nbsp;
						<Link to={Router.routes.COURSES.path}>
							<Button size='sm' variant='link'>Learn more</Button>
						</Link>
					</ListGroup.Item>
					<ListGroup.Item><h5>IPM Megafinal paper solutions</h5>
						<Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'prabhutvaSolutions' })}>
							<Button size='sm' variant='primary'>View Prabhutva 2018 paper solutions for free</Button>
						</Link>
						&nbsp;&nbsp;
						<Link to={Router.routes.COURSES.path}>
							<Button size='sm' variant='link'>Learn more</Button>
						</Link>
					</ListGroup.Item>
				</ListGroup>
			</Col>
			<Col md={{ span: 5 }}>
				<ListGroup variant='flush'>
					<ListGroup.Item><h5>Pradnya paper solutions for Std V</h5>
						<Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'pradnyaSolutions' })}>
							<Button size='sm' variant='primary'>View Pradnya 2012 paper solutions for free</Button>
						</Link>
						&nbsp;&nbsp;
						<Link to={Router.routes.COURSES.path}>
							<Button size='sm' variant='link'>Learn more</Button>
						</Link>
					</ListGroup.Item>
					<ListGroup.Item><h5>Prabhutva level-2 paper solutions for Std V</h5>
						<Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'prabhutvaSolutions' })}>
							<Button size='sm' variant='primary'>View Prabhutva 2018 paper solutions for free</Button>
						</Link>
						&nbsp;&nbsp;
						<Link to={Router.routes.COURSES.path}>
							<Button size='sm' variant='link'>Learn more</Button>
						</Link>
					</ListGroup.Item>
				</ListGroup>
			</Col>
		</Row>
	</div>

export const IndexPage = () =>
	<DefaultLayout>
		<div>
			{/* <AnnouncementsBanner /> */}
			{/* <br /> */}
			{/* <OnlineBanner /> */}
			{/* <hr /> */}
			{/* <h5>After 20 years of success in Mumbai, relocating our operations to Pune!</h5> */}
			<AboutContent />
			<br />
		</div>
	</DefaultLayout>
