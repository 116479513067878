import React from 'react'
import { Link } from 'react-router-dom'

export const BackCrumbs = (props) => 
    <div>
        <span style={{fontSize: '0.8em'}}>
            <Link to={props.path}>&#171; { props.msg || 'Back' }</Link>
        </span>
        <span style={{fontVariant: 'small-caps', textDecoration: 'underline'}}>{ props.title || '' }</span>
    </div>
