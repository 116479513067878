import React from 'react'
import { Link } from 'react-router-dom'
import { Router } from 'app'
import { SolutionsPageBase } from 'view/page/Course/Common/SolutionsPageBase'

export const MathFoundationsPage = () => 
    <SolutionsPageBase 
        courseName='mathFoundations'
        courseTitle={<>Foundations of Mathematics</>}
        courseDescription={<>For more information, please visit the <Link to={Router.routes.MATH_FOUNDATIONS.path}>course main page</Link></>}
        courseGoldHeader={<>Please register to access all the course content</>}
    />