import React from 'react'
import { generatePath } from 'react-router-dom'
import { API, Store, Router } from 'app'
import { FluidFullLayout } from 'view/component/Layout'
import { DocumentView } from 'view/component/Document'
import { BackCrumbs, useDataLoader } from 'view/component/Util'

export const DocumentViewPage = (props) => {
	var params = props.match.params
	const auth = Store.useSelector(state => state.auth)
	const {data, viewData} = useDataLoader({
        name: 'document',
        loader: async () => await API.methods.COURSE_DOCUMENT_VIEW({name: params.course, id: params.id}),
		setter: payload => payload.document,
		deps: [auth, params.course, params.id]
	})

	return <FluidFullLayout>
		<BackCrumbs path={generatePath(Router.routes.COURSE_VIEW.path,{ course: params.course })}
			msg='Back to course page' />
			{ viewData(() => <DocumentView {...data} />)}
	</FluidFullLayout>
}