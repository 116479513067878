import React from 'react'
import { Router } from 'app'
import { BackCrumbs } from 'view/component/Util'
import { NarrowLayout } from 'view/component/Layout'

export const AboutPage = () =>
	<NarrowLayout>
		<BackCrumbs path={Router.routes.INDEX.path} msg='Back to homepage' />
		<AboutContent />
	</NarrowLayout>

export const AboutContent = () =>
	<>
		<h5>About us</h5>
		Since 2001, we at Sohani Academy have been training students for competitive exams in mathematics and science.<br />
		We started our activities in Vile Parle, Mumbai. In 2022, we have relocated our operations to Pune.<br />
		In addition to regular school mathematics and science; we also specialize in training students for exams such as:
		<ul>
			<li>Government Scholarship Exams (M.S.S.E. and H.S.S.E.)</li>
			<li>Pravinya, Pradnya and Prabhutva exams in mathematics</li>
			<li>IPM Scholarship exams for Std III to Std IX</li>
		</ul>

		<h5>Online training courses</h5>
		Throughout our journey, we have introduced various kinds of online practice material and training courses,
		so that students can continue to benefit from our experienced faculty members, regardless of geographical distances.
		Many of our original online services (some dating as early as 2009) are still available on our older website: <a href="https://sohaniacademy.com" target="_blank" rel="noopener noreferrer">sohaniacademy.com</a>.<br />
		At sohaniacademy.org, we continue to build upon this legacy, by offering a new generation of online courses, including training for Mathematical Olympiads.<br /><br />
		<AboutFaculty />
	</>

const AboutFaculty = () =>
	<>
		<h5>Our faculty</h5>
		<h6>Sunil Sohani</h6>
		With a B.E. in mechanical engineering and a Diploma in Management Studies; Sunil Sohani began his career in the automobile industry.<br />
		After working for 17 years with reputed companies such as Premier Automobiles and General Motors; he switched gears by converting his interest in teaching as a hobby, into a full-time profession.<br />
		Since the foundation of Sohani Academy in 2001, Sunil has trained thousands of students for various competitive exams in school mathematics; with a very high success rate in such exams.<br /><br />
		<h6>Anandamayee Sohani</h6>
		With an M.Sc. in biochemistry, Anandamayee has been working as a teacher for more than 35 years; addressing students of all age groups.<br />
		Over the last 20 years; she has been training students for competitive exams, as well as a regular or advanced curriculum in science.<br />
		Along with core academics, Anandamayee also likes helping students to gain many soft skills, through various personality development workshops that she has designed and conducted.<br /><br />
		<h6>Prashant Sohani</h6>
		Prashant graduated from IIT Bombay in 2012 with a B.Tech. in Electrical Engineering, and since then, he is working at NVIDIA, Pune.<br />
		He is also currently a member of the Mathematical Olympiads Advisory Committee, created under the National Board for Higher Mathematics.<br />
		Here is some more information about his academic background and experience:
        <ul>
			<li>Topper from the city of Pune in JEE 2008, with an All-India Rank of 117.</li>
			<li>Bronze medalist as a contestant at the International Mathematical Olympiad 2008.</li>
			<li>Deputy leader for the Indian team to the International Mathematical Olympiad 2018.</li>
			<li>Regional coordinator for RMO and INMO, Maharashtra and Goa region, from 2016 to 2022.</li>
		</ul>
		Over the last 12 years, Prashant has accumulated over 2000 hours of training experience across all stages of the Mathematical Olympiad exams.
		</>