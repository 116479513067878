import React, { useState, useEffect } from 'react'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import 'style/component/document/documentView.css'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return { width, height }
} 
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowDimensions
}
  
export const DocumentPdfView = (props) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const width = useWindowDimensions().width

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages)
  const changePage = (offset) => setPageNumber(prevPageNumber => prevPageNumber + offset)
  const previousPage = () => changePage(-1)
  const nextPage = () => changePage(1)

  const stopEvent = (e) => { 
    e.preventDefault()
    e.stopPropagation()
  }

  if (!props.type){
      return <i>Loading..</i>
  }
  if (props.type === 'img'){
      return <>
      <img alt={ props.name } src={`data:image/jpeg;base64,${props.data}`} style={{'width':'100%'}} />
      </>
  } else if (props.type === 'pdf'){
      return <>
      <h4> { props.name } </h4>
      <div className='docArea'>
        <div className='navbar justify-content-center navbar-dark bg-dark'>
          <span className='navbar-text' style={{'paddingRight':'20px'}}>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </span>
          <div className='btn-group' role='group'>
            <button type='button' className='btn btn-secondary' disabled={pageNumber <= 1} onClick={previousPage}>Previous page</button>
            <button type='button' className='btn btn-secondary' disabled={pageNumber >= numPages} onClick={nextPage}>Next page</button>
          </div>
        </div>
        <span onClickCapture={stopEvent} onContextMenuCapture={stopEvent} style={{'userSelect':'none'}}>
        <Document file={{data:new Uint8Array(atob(props.data).split('').map(c => c.charCodeAt(0))) }} 
            onLoadSuccess={onDocumentLoadSuccess} className='noSelect'>
          <Page pageNumber={pageNumber} width={Math.max(300, 0.8 * width)} />
        </Document>
        </span>
        <div className='watermark'>
          <span>
            SOHANI ACADEMY<br />
            <span className='url'>www.sohaniacademy.org</span>
          </span>
        </div>
      </div>
  </>
  } else {
      return <i>Unsupported type</i>
  }
}