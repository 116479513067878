import axios from 'axios'
import { Logger } from './Logger'

export class API {
    static api_root = `${process.env.REACT_APP_API_ROOT}`

    static request = async (url, method, data={}, params={}) => {
        API.debugPrint('SEND', method, url, {data, params})
        var response
        try {
            response = await axios({method, baseURL: API.api_root,
                                    url, params, data, withCredentials: true})
        } catch (err) {
            API.debugPrint('NETERR', method, url, err.message)
            throw err
        }
        if (response.data.status === 'SUCCESS'){
            API.debugPrint('RECV', method, url, response.data)
            return response.data.data
        } else {
            API.debugPrint('APIERR', method, url, response.data)
            throw Error(`${response.data.message}`)
        }
    }

    static get = (url) => (params) => API.request(url, 'GET', {}, params)
    static post = (url) => (data) => API.request(url, 'POST', data, {})

    static debugPrint = (status, method, pathName, data) => {
        var symb, col
        switch(status){
            case 'SEND':
                [symb, col] = ['>>', 'blue']
                break
            case 'RECV':
                [symb, col] = ['<<', 'green']
                break
            case 'APIERR':
                [symb, col] = ['!<', 'orange']
                break
            case 'NETERR':
                [symb, col] = ['<<', 'red']
                break
            default:
                [symb, col] = ['<<', 'red']
        }
        Logger.devStyleLog(`${symb} ${method} %c ${pathName}`, `color:${col}`, data)
    }

    static methods = {
        INIT: API.get('init'),
        AUTH_INIT: API.get('auth/init'),
        AUTH_GOOGLE_LOGIN: API.post('auth/google_login'),
        AUTH_LOGOUT: API.post('auth/logout'),
        COURSE_ALL: API.get('course/all'),
        COURSE_VIEW: API.get('course/view'),
        COURSE_DOCUMENT_VIEW: API.get('course/document/view'),
        COURSE_IMAGE_VIEW: API.get('course/image/view'),
        COURSE_PROBLEM_COMMENTS_VIEW: API.get('course/problem/comments/view'),
        COURSE_PROBLEM_COMMENTS_ADD: API.post('course/problem/comments/add'),
        ADMIN_CACHE_INVALIDATE: API.post('admin/cache/invalidate'),
        ADMIN_CACHE_UPDATE: API.post('admin/cache/update'),
        ADMIN_CACHE_REBUILD: API.post('admin/cache/rebuild'),
        ADMIN_USERS_GET: API.get('admin/users'),
        ADMIN_USER_UPDATE: API.post('admin/users/update'),
        ADMIN_WORKSHEET_PROBLEM_ADD: API.post('admin/worksheet/problem/add'),
        ADMIN_PROBLEM_UPDATE: API.post('admin/problem/update'),
        ADMIN_IMAGE_UPLOAD: API.post('admin/image/upload'),
    }
    static paths = {
        COURSE_IMAGE_VIEW: 'course/image/view'
    }
}