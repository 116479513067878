import React from 'react'
import { Router } from 'app'
import { BackCrumbs } from 'view/component/Util'
import { DefaultLayout } from 'view/component/Layout'

export const ContactPage = () => 
	<DefaultLayout>
		<BackCrumbs path={Router.routes.INDEX.path} msg='Back to homepage' />
		<ContactContent />
	</DefaultLayout>

export const ContactContent = () => 
	<>
		<h5>Contact us:</h5>
		41, D-wing, Shreeniwas Apts<br />
		Opp. Green Pharmacy, Manisha Society<br />
		Karvenagar, Pune - 411052.<br />
		<b>Phone:</b> (+91) 9820379104 / (+91) 7798190145 <br />
		<b>Email:</b> <a href='mailto:smsohani@gmail.com'>smsohani@gmail.com</a> &nbsp;|&nbsp;
			<a href='mailto:prashant.sohani@gmail.com'>prashant.sohani@gmail.com</a> &nbsp;|&nbsp;
			<a href='mailto:admin@sohaniacademy.org'>admin@sohaniacademy.org</a>
	</>