import React, { useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { ProblemMgmtView } from './ProblemMgmtView'

export const WorksheetMgmtView = (props) => {
	const worksheet = props.worksheet
	const [curProblem, setCurProblem] = useState(-1)

	const setProblemData = (data) => {
		var contents = [...worksheet.contents]
		contents[curProblem].problem = data
		props.setWorksheetContent({...worksheet, contents})
	}

	if (curProblem >= 0){
		return <ProblemMgmtView problem={worksheet.contents[curProblem].problem}
				setProblemData={setProblemData} setCurProblem={setCurProblem} />
	} else {
		var problems = []
		for (let idx in worksheet.contents){
			var problem = worksheet.contents[idx].problem
			problems.push(<tr key={idx}>
				<td>{ idx }</td>
				<td>{ problem._id }</td>
				<td>{ problem.question }</td>
				<td>
					<Button size="sm" variant="link" onClick={() => setCurProblem(idx)}>Edit</Button>
					</td>
				</tr>)
		}
		return <>
			<Button size="sm" variant="link" onClick={() => props.setCurWorksheet(-1)} >Back to course</Button>
			<h5>Worksheet _id: { worksheet._id }</h5>
				Release Date: { new Date(worksheet.releaseDate).toDateString() }
			<Table>
				<thead>
					<tr><th>Sr</th><th>ID</th><th>Question</th><th>Actions</th></tr>
				</thead>
				<tbody>
					{ problems }
				</tbody>
			</Table>
		</>		
	}
}
