import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Table } from 'react-bootstrap'
import { Router, CoursesInfo } from 'app'
import { DefaultLayout } from 'view/component/Layout'

const Row = (props) => {
    return <tr>
        <td>{ props.info.commonName }</td><td>{ props.subscription.status }</td>
    </tr>
}

export const UserSubscriptionsManager = () => {
    const auth = useSelector(state => state.auth)
    const coursesInfo = useSelector(state => state.coursesInfo)

    const render = () => {
        var rows = []
        if (coursesInfo){
            for(var course in coursesInfo.courses){
                rows.push(<Row key={course} course={course} info={coursesInfo.courses[course]} subscription={CoursesInfo.checkSubscription(coursesInfo, auth, course)}></Row>)
            }    
        }
        return <Table>
            <thead>
                <tr>
                    <th>Course</th><th>Status</th>
                </tr>
            </thead>
            <tbody>
                { rows }
            </tbody>
        </Table>
    }

    return render()
}

export const UserSubscriptionsPage = () => 
    <DefaultLayout>
        <h2>Subscriptions</h2>
        <UserSubscriptionsManager />
        <Link to={Router.routes.USER_SETTINGS.path}>Back to user settings page</Link>
    </DefaultLayout>