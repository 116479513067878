import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import { Router } from 'app'
import { BackCrumbs } from 'view/component/Util'
import { DefaultLayout } from 'view/component/Layout'
import 'style/page/Index/indexPage.css'

export const CoursesPage = () => 
	<DefaultLayout>
		<BackCrumbs path={Router.routes.INDEX.path} msg='Back to homepage' />
            <h4>Online training courses</h4>
            <h5><Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'prmoPractice'} )}>IOQM question bank</Link></h5>
            <ul>
                <li>This course is currently <b>free for all students</b>. Simply sign in and start browsing!</li>
                <li>6 new questions every week; for a duration 20 weeks (a total of 100+ problems)</li>
                <li>Each question is supplemented with hints, solution and additional tips for further reading and practice.</li>
                {/* <li>Registration fee: <b>Rs 999/- only</b>, inclusive of all taxes</li>
                <li>The worksheets can be accessed any number of times for a <b>duration of 1 year</b> from the date of registration</li>
                <li>After registration, you will also get access to the comments section below each question;<br />
                where you can discuss with others, and even post your own new solutions!</li> */}
            </ul>
            <h5><Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'pradnyaSolutions'} )}>Pradnya paper solutions for Std V (2010-2016)</Link></h5>
            <ul>
                <li>2 free sample paper solutions available (2012 and 2014)</li>
                <li>Complete set of 7 paper solutions (2010-2016) for <b>Rs 400/- only</b>, inclusive of all taxes</li>
                <li>The paper solutions can be accessed any number of times for a <b>duration of 1 year</b> from the date of registration</li>
                <Link to={Router.routes.PAYMENT.path}>How to subscribe</Link>
            </ul>
            <h5><Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'prabhutvaSolutions'} )}>Prabhutva level-2 paper solutions for Std V (2012-2018)</Link></h5>
            <ul>
                <li>2 free sample paper solutions available (2015 and 2018)</li>
                <li>Complete set of 7 paper solutions (2012-2018) for <b>Rs 400/- only</b>, inclusive of all taxes</li>
                <li>The paper solutions can be accessed any number of times for a <b>duration of 1 year</b> from the date of registration</li>
                <Link to={Router.routes.PAYMENT.path}>How to subscribe</Link>
            </ul>
            <h5><Link to={generatePath(Router.routes.COURSE_VIEW.path, { course: 'ipmMegafinalSolutions'} )}>IPM Megafinal paper solutions</Link></h5>
            <ul>
                <li>Free sample paper solution available</li>
                <li>Complete set of paper solutions for <b>Rs 400/- only</b>, inclusive of all taxes</li>
                <li>The paper solutions can be accessed any number of times for a <b>duration of 1 year</b> from the date of registration</li>
                <Link to={Router.routes.PAYMENT.path}>How to subscribe</Link>
            </ul>
	</DefaultLayout>