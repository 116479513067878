/* global MathJax */
import React, { useState, useEffect} from 'react'
import { Row, Col, Button, Card, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { API, Store } from 'app'
import { useDataLoader, RoleChecker } from 'view/component/Util'
import { DefaultLayout } from 'view/component/Layout'
import { CommentStreamView } from './CommentStreamView'
import 'style/page/Course/PRMO/prmoPracticePage.css'

const EditModal = (props) => {
	const [value, setValue] = useState(props.value)
	const handleChange = (e) => setValue(e.target.value)
	const handleClose = () => {
        props.setField(props.field, value)
		props.handleCloseModal()
    }
    if (props.show) {
        return <Modal size='lg' show={props.show} onHide={handleClose}>
		<Modal.Header>Editing { props.name }</Modal.Header>
		<Modal.Body>
			<Form>
				<Form.Group>					
					<Form.Control as="textarea" rows="3" value={value} onChange={handleChange} />
				</Form.Group>
			</Form>
		</Modal.Body>
	</Modal>
    } else {
        return <></>
    }
}

const TagsView = (props) => {
    const [editing, setEditing] = useState(false)    
    const handleCloseModal = () => {
		setEditing(false)
	}
    var tags = []
    for(var tag of props.body.split(" ")){
        tags.push(<Button key={tag} size='sm' variant={props.field==='topic'?'outline-success':'outline-primary'}
            style={{cursor:'default',fontFamily:'monospace',borderRadius:'15px',margin:'3px',padding:'0px 5px'}}>
            <b>{ tag }</b></Button>)
    }
    return <>
        { tags }
        { props.setField ?< RoleChecker role='ROLE_ADMIN'>
                <Button size='sm' onClick={() => setEditing(true)}>Edit</Button>
                </RoleChecker>:<></> }
        <EditModal show={editing} name={props.name} field={props.field} value={props.body}
			 setField={props.setField} handleCloseModal={handleCloseModal} />
    </>
}

const parseToHtml = (str) => str.replace(/\n/g,'<br />').replace(
    /<img_id=([a-z0-9]*) \/>/g,
    `<div style="text-align:center"><img src="${API.api_root}/${API.paths.COURSE_IMAGE_VIEW}?_id=$1" /></div>`)
     
const getValueOrMessage = (field) => {
    if (field.available){
        return <span dangerouslySetInnerHTML={{__html:parseToHtml(field.value)}} />
    } else {
        if (field.reason === 'UNRELEASED'){
            return <Button size='sm' variant='secondary' disabled>Not released yet</Button>
        } else if (field.reason === 'NEED_GOLD'){
            return <Button size='sm' variant='success' disabled>Please subscribe to view</Button>
        } else if (field.reason === 'NEED_USER'){
            return <Button size='sm' variant='success' disabled>Please sign in to view</Button>
        }
    }
}

const FieldView = (props) => {
	const [editing, setEditing] = useState(false)
    useEffect(() => {
        console.log(props.body)
        MathJax.typeset()
    }, [props.body])
	const handleCloseModal = () => {
		setEditing(false)
	}
    return <div>
        <div>
            { getValueOrMessage(props.body)}
        </div>
        <RoleChecker role='ROLE_ADMIN'>
            <Button size='sm' onClick={() => setEditing(true)}>Edit</Button>
        </RoleChecker>
        <EditModal show={editing} name={props.name} field={props.field} value={props.body.value}
			 setField={props.setField} handleCloseModal={handleCloseModal} />
    </div>
}

const ClickToRevealWrapper = (props) => {
    const [revealed, setRevealed] = useState(false)
    return <div>
        { (revealed || !props.available) ? props.children : 
        <div>
            <Button size='sm' onClick={() => setRevealed(true)} variant='outline-primary'
                style={{cursor:'pointer'}}>
                { props.message }
            </Button>
        </div>
        }
    </div>
}

const ProblemView = (props) => {
    const setField = async (field, value) => {
        try {
            await API.methods.ADMIN_PROBLEM_UPDATE({_id: props.problem._id, field, value})
            props.setProblemField(field, value)
            Store.actions.FLASH_ADD.dispatch({type:'INFO',message:'Changes saved'})    
        } catch (error) {
            console.log(error)
            Store.actions.FLASH_ADD.dispatch({type:'WARNING',message:'Unable to save changes'})    
        }
    }

    return <div style={{padding:'10px'}}>
        <Card>
            <Card.Body>
                <FieldView body={props.problem.question} field='question' setField={setField} />
                <TagsView body={props.problem.topic} field='topic' setField={setField} />
                <TagsView body={props.problem.level} field='level' setField={setField} />
            </Card.Body>
        </Card>
        <h4>Hints</h4>
        <ClickToRevealWrapper message='Reveal hint 1' available={props.problem.hint1.available}>
            <div>
                <h6>Hint 1:</h6>
                <FieldView body={props.problem.hint1} field='hint1' setField={setField} />
            </div>
        </ClickToRevealWrapper><br />
        <ClickToRevealWrapper message='Reveal hint 2' available={props.problem.hint2.available}>
            <div>
                <h6>Hint 2:</h6>
                <FieldView body={props.problem.hint2} field='hint2' setField={setField} />
            </div>
        </ClickToRevealWrapper><br />
        <h4>Solution</h4>
        <ClickToRevealWrapper message='Reveal the solution' available={props.problem.solution.available}>
            <div>
                <FieldView body={props.problem.solution} field='solution' setField={setField} />
                <br />
                <h5>Additional Remarks</h5>
                <FieldView body={props.problem.remarks} field='remarks' setField={setField} />
            </div>
        </ClickToRevealWrapper><br />
        <h4>Discussion</h4>
        <ClickToRevealWrapper message='Reveal comments (Warning; may contain spoilers!)' available={true}>
            <CommentStreamView problemId={props.problem._id} />
        </ClickToRevealWrapper>
    </div>
}

const WorksheetView = (props) => {
    useEffect(() => {
        MathJax.typeset()
    }, [props.sheet])

    const clickToOpenTooltip = (props) => <Tooltip id="problem-tooltip" {...props}>
    Click to open
    </Tooltip>

    const shorten = (question) => {
        if (question.available){
            return <span dangerouslySetInnerHTML={{__html: question.value.substring(0,80)+'...'}} />
        } else {
            return getValueOrMessage(question)
        }
    }
    var sheet = props.sheet
    var problems = []
    for(let idx in sheet.contents){
        idx = parseInt(idx)
        var p = sheet.contents[idx]
        problems.push(<div key={ p.problem._id } style={{borderBottom:'1px solid #CCCCCC'}}>
            { p.problem.question.available ? 
                <OverlayTrigger placement='left' overlay={clickToOpenTooltip}>
                    <span style={{display:'block', padding: '10px', cursor:'pointer'}} onClick={() => props.setCurProblem(idx)}>
                        Problem {parseInt(idx)+1}:
                        <span style={{position:'absolute',right:'0px'}}>
                            <TagsView body={p.problem.topic} field='topic' setField={null} />
                            <TagsView body={p.problem.level} field='level' setField={null} />
                        </span>
                        <div>
                            <div className="problemPartialRow">
                                { shorten(p.problem.question) }
                            </div>
                        </div>    
                    </span>                
                </OverlayTrigger> : 
                <span style={{display:'block', padding: '10px'}}>
                        Problem {parseInt(idx)+1}:
                        <div>
                            <div className="problemPartialRow">
                                { getValueOrMessage(p.problem.question) }
                            </div>
                        </div>
                </span>
            }
        </div>)
    }
    return <div>
            { problems }
        </div>
}

export const PrmoPracticePage = (props) => {
    const courseName = 'prmoPractice'
    const [curWorksheet, setCurWorksheet] = useState(0)
    const [curProblem, setCurProblem] = useState(-1)    
    const {data, setData, viewData} = useDataLoader({
        name: 'course',
        loader: async () => await API.methods.COURSE_VIEW({name: courseName}),
        setter: payload => {
            var curWeek = new Date()
            curWeek.setDate(curWeek.getDate() - 7)
            for (var resIdx in payload.course.resources){
                var releaseWeek = new Date(payload.course.resources[resIdx].document.content.releaseDate)
                if (releaseWeek > curWeek){
                    setCurWorksheet(parseInt(resIdx))
                    break
                }
            }
            return payload.course
        },
    })

    const wsheetNav = () => {
        var curSheet = data.resources[curWorksheet].document.content
        let numSheets = data.resources.length

        return <>
                <Row>
                    <Col xs={{ span:6, order: 2}} sm={{span:3, order: 1}} style={{textAlign:'center'}}>
                    <Button size="sm" variant="link" style={{fontSize:'1.2em', verticalAlign:'0'}}
                    disabled={curWorksheet === 0} onClick={() => setCurWorksheet(curWorksheet - 1)}>
                        &larr; Previous worksheet
                    </Button>
                    </Col>
                    <Col xs={{ span:12, order: 1}} sm={{span:6, order: 2}} style={{textAlign:'center'}}>
                        <span style={{fontSize:'1.5em'}}>{ curSheet.name }</span>
                        <div style={{textAlign:'center'}}>
                            <i>{ new Date(curSheet.releaseDate).toDateString() }</i>
                        </div>
                    </Col>
                    <Col xs={{ span:6, order: 3}} sm={{span:3, order: 3}} style={{textAlign:'center'}}>
                    <Button size="sm" variant="link" style={{fontSize:'1.2em', verticalAlign:'0'}}
                    disabled={curWorksheet === numSheets-1} onClick={() => setCurWorksheet(curWorksheet + 1)}>
                        Next worksheet &rarr;
                    </Button><br />
                    </Col>
                </Row>
            </>
    }
    const setProblemField = (field, value) => {
        var newData = {...data}
        if (field === 'topic' || field === 'level'){
            newData.resources[curWorksheet].document.content.contents[curProblem].problem[field] = value
        } else {
            newData.resources[curWorksheet].document.content.contents[curProblem].problem[field].value = value
        }
        setData(newData)
        MathJax.typeset()
    }

    const courseContents = () => {
        var titlebarCrumb, content
        if (curProblem >= 0){
            titlebarCrumb = <Button size='sm' variant='link' onClick={() => setCurProblem(-1)}>Back to worksheet</Button>
            var curSheet = data.resources[curWorksheet].document.content
            var numProblems = curSheet.contents.length
            content = <>
                <Row>
                    <Col xs={{ span:6, order: 2}} sm={{span:3, order: 1}} style={{textAlign:'center'}}>
                    <Button size="sm" variant="link" style={{fontSize:'1.2em', verticalAlign:'0'}}
                        disabled={curProblem === 0} onClick={() => setCurProblem(curProblem - 1)}>
                        &larr; Previous problem
                    </Button>
                    </Col>
                    <Col xs={{ span:12, order: 1}} sm={{span:6, order: 2}} style={{textAlign:'center'}}>
                        <span style={{fontSize:'1.5em'}}>{ curSheet.name }; Problem {parseInt(curProblem)+1}</span>
                    </Col>
                    <Col xs={{ span:6, order: 3}} sm={{span:3, order: 3}} style={{textAlign:'center'}}>
                    <Button size="sm" variant="link" style={{fontSize:'1.2em', verticalAlign:'0'}}
                        disabled={curProblem === numProblems-1} onClick={() => setCurProblem(curProblem + 1)}>
                        Next problem &rarr; 
                    </Button>
                    </Col>
                </Row>
                <div style={{textAlign:'center'}}>
                </div>
                <ProblemView key={curProblem} problem={curSheet.contents[curProblem].problem} 
                    curWorksheet={curWorksheet} curProblem={curProblem} setProblemField={setProblemField} />
            </>
        } else {
            titlebarCrumb = <></>
            content = <>
                <div>
                    { wsheetNav() }
                </div>
                <div>
                    <WorksheetView key={curWorksheet} sheet={ data.resources[curWorksheet].document.content } setCurProblem={setCurProblem} />                    
                </div>
            </>
        }
        return <>
            <Row>
                <Col xs={12} sm={4}>
                    { titlebarCrumb }
                </Col>
                <Col xs={12} sm={4} style={{textAlign:'center'}}>
                <span style={{fontVariant: 'small-caps' }}>PRMO Practice Bank</span>
                </Col>
            </Row>
            { content }
        </>
    }

    return <DefaultLayout>
        { viewData(courseContents) }
    </DefaultLayout>
}