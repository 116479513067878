import React from 'react'
import { Store } from 'app'
import 'style/component/flashBoard.css'

export const FlashBoard = (props) => {
    var msgBlocks = []
    const flash = Store.useSelector(state => state.flash)
    for(var msg of flash.messages){            
        msgBlocks.push(<FlashMessage key={ msg.key } msg={ msg } />)
    }
    return <div className='flashContainer'>{ msgBlocks }</div>
}

const flashClass = {
    'SUCCESS': 'alert alert-success alert-dismissable',
    'INFO': 'alert alert-info alert-dismissable',
    'WARNING': 'alert alert-warning alert-dismissable',
    'DANGER': 'alert alert-danger alert-dismissable',
}

const opacityClass = {
    'VISIBLE': 'alert-visible',
    'FADING': 'alert-fading',
}

class FlashMessage extends React.Component {
    constructor(props){
        super(props)
        setTimeout(this.close, 3000)
    }
    remove = () => {
        Store.actions.FLASH_CLOSE_FINISH.dispatch(this.props.msg.key)
    }

    close = () => {
        Store.actions.FLASH_CLOSE_START.dispatch(this.props.msg.key)
        setTimeout(this.remove, 3000)
    }

    render = () => 
        <div className={ `${opacityClass[this.props.msg.state]} ${flashClass[this.props.msg.type]}` }>
            <button type='button' className='close' onClick={this.close}>
            &nbsp;&nbsp;&nbsp;
            <span aria-hidden='true'>&times;</span>
            </button>
            { this.props.msg.message }
        </div>
}