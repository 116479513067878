import React, { useEffect } from 'react'
import { API, Auth, CoursesInfo, Logger, Store } from 'app'

export const Main = (props) => {
    const debug = Store.useSelector(state => state.app.debug)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            const data = await API.methods.INIT()
            Store.actions.APP_API_VERSION_SET.dispatch({apiVersion: data.app.apiVersion, apiBuildDate: data.app.apiBuildDate})
            Auth.init(data.auth)
            CoursesInfo.init(data.coursesInfo)
        } catch (err) {
            Logger.devLog('App init failed', err)
        }
    }

    const handleKey = event => {
        if((event.key === '~') && (event.ctrlKey) && (event.shiftKey)){
            Store.actions.FLASH_ADD.dispatch({type: 'INFO', message: `Debug set to ${!debug}`})
            Store.actions.APP_DEBUG_SET.dispatch(!debug)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKey)
        return () => {
            window.removeEventListener('keydown', handleKey)
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [debug])
    
    return <></>
}