import React from 'react'
import { Router } from 'app'
import { BackCrumbs } from 'view/component/Util'
import { DefaultLayout } from 'view/component/Layout'
import 'style/page/Index/indexPage.css'

export const TermsPage = () => 
	<DefaultLayout>
        <BackCrumbs path={Router.routes.INDEX.path} msg='Back to homepage' />
        <TermsContent />
    </DefaultLayout>

export const TermsContent = () => 
    <>
        <h4>Terms and Conditions</h4>
        <ul>
            <li>All the available resources can be viewed online by the user from any number of devices</li>
            <li>Downloading, printing or sharing the material is not permitted; and may result in termination of membership</li>
            <li>While the website administration makes every effort to keep the resources updated and accurate; all the resources are provided 'as is' and without warranties of any kind 
                (express, implied, and statutory, including but not limited to the implied warranties of merchantability and fitness for a particular purpose)</li>
        </ul>
        <h4>Cancellation/Refund policy</h4>
        <ul>
            <li>In case the user experiences issues in browsing the website; they may contact us for a resolution</li>
            <li>In rare circumstances; the user may be provided an offline copy of the material. The decision will be solely at the discretion of the website administrators</li> 
            <li>Orders once placed will not be refunded under any circumstances</li>
        </ul>
 	</>