import React from 'react'
import { Link } from 'react-router-dom'
import { Router } from 'app'
import { DefaultLayout } from 'view/component/Layout'

export const UserSettingsPage = () =>
    <DefaultLayout>
        <h2> Settings</h2>
        <Link to={Router.routes.USER_SUBSCRIPTIONS.path}>Manage active subscriptions</Link><br />
        <Link to={Router.routes.USER_HOME.path}>Back to user page</Link>
    </DefaultLayout>