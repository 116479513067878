import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import { Router } from 'app'
import { GeneratePageBase } from './GeneratorPageBase'

const GeneratePage4x3 = () => {
    const num_questions = 10
    const title = '4 x 3 multiplication'
    const pattern = '4_3_mul'

    const randInt = (min, max) => {
        return Math.floor(Math.random() * (max - min) ) + min
    }

    const genQuestion = () => {
        var data = {
            a: randInt(1000,9999),
            b: randInt(100,999)
        }
        data.c = data.a * data.b
        return data
    }

    const formatQuestion = (data, key) => {        
        var [a_0, a_1, a_2, a_3] = data.a.toString().split('').reverse()
        var [b_0, b_1, b_2] = data.b.toString().split('').reverse()
        return <>
            <h4>Q {key}</h4>
            <table key={key}>
                <tr>
                    <td>{ a_3 }</td><td>{ a_2 }</td><td>{ a_1 }</td><td>{ a_0 }</td>
                </tr>
                <tr>
                    <td></td><td>{ b_2 }</td><td>{ b_1 }</td><td>{ b_0 }</td>
                </tr>
                <tr style={{borderTop:'1px solid black'}}>
                    <td colspan='4'>&nbsp;</td>
                </tr>
            </table>
            <hr />
        </>
    }

    const formatAnswer = (data, key) => {
        var [a_0, a_1, a_2, a_3] = data.a.toString().split('').reverse()
        var [b_0, b_1, b_2] = data.b.toString().split('').reverse()
        var [c_0, c_1, c_2, c_3, c_4, c_5, c_6] = data.c.toString().split('').reverse()
        return <>
            <h4>Q {key}</h4>
            <table key={key}>
                <tr>
                    <td></td><td></td><td></td><td>{ a_3 }</td><td>{ a_2 }</td><td>{ a_1 }</td><td>{ a_0 }</td>
                </tr>
                <tr>
                    <td></td><td></td><td></td><td></td><td>{ b_2 }</td><td>{ b_1 }</td><td>{ b_0 }</td>
                </tr>
                <tr style={{borderTop:'1px solid black'}}>
                    <td>{ c_6 }</td><td>{ c_5 }</td><td>{ c_4 }</td><td>{ c_3 }</td><td>{ c_2 }</td><td>{ c_1 }</td><td>{ c_0 }</td>
                </tr>
            </table>
        </>
    }

    return <GeneratePageBase num_questions={num_questions} title={title} pattern={pattern}
            genQuestion={genQuestion} formatQuestion={formatQuestion} formatAnswer={formatAnswer} />
}

const GeneratePage3x2 = () => {
    const num_questions = 10
    const title = '3 x 2 multiplication'
    const pattern = '3_2_mul'

    const randInt = (min, max) => {
        return Math.floor(Math.random() * (max - min) ) + min
    }

    const genQuestion = () => {
        var data = {
            a: randInt(100,999),
            b: randInt(10,99)
        }
        data.c = data.a * data.b
        return data
    }

    const formatQuestion = (data, key) => {
        var [a_0, a_1, a_2] = data.a.toString().split('').reverse()
        var [b_0, b_1] = data.b.toString().split('').reverse()
        return <>
            <h4>Q {key}</h4>
            <table key={key}>
                <tr>
                    <td>{ a_2 }</td><td>{ a_1 }</td><td>{ a_0 }</td>
                </tr>
                <tr>
                    <td></td><td>{ b_1 }</td><td>{ b_0 }</td>
                </tr>
                <tr style={{borderTop:'1px solid black'}}>
                    <td colspan='3'>&nbsp;</td>
                </tr>
            </table>
            <hr />
        </>
    }

    const formatAnswer = (data, key) => {
        var [a_0, a_1, a_2] = data.a.toString().split('').reverse()
        var [b_0, b_1] = data.b.toString().split('').reverse()
        var [c_0, c_1, c_2, c_3, c_4] = data.c.toString().split('').reverse()
        return <>
            <h4>Q {key}</h4>
            <table key={key}>
                <tr>
                    <td></td><td></td><td>{ a_2 }</td><td>{ a_1 }</td><td>{ a_0 }</td>
                </tr>
                <tr>
                    <td></td><td></td><td></td><td>{ b_1 }</td><td>{ b_0 }</td>
                </tr>
                <tr style={{borderTop:'1px solid black'}}>
                    <td>{ c_4 }</td><td>{ c_3 }</td><td>{ c_2 }</td><td>{ c_1 }</td><td>{ c_0 }</td>
                </tr>
            </table>
        </>
    }

    return <GeneratePageBase num_questions={num_questions} title={title} pattern={pattern}
            genQuestion={genQuestion} formatQuestion={formatQuestion} formatAnswer={formatAnswer} />
}


const GeneratePage2x2 = () => {
    const num_questions = 10
    const title = '2 x 2 multiplication'
    const pattern = '2_2_mul'

    const randInt = (min, max) => {
        return Math.floor(Math.random() * (max - min) ) + min
    }

    const genQuestion = () => {
        var data = {
            a: randInt(10,99),
            b: randInt(10,99)
        }
        data.c = data.a * data.b
        return data
    }

    const formatQuestion = (data, key) => {
        var [a_0, a_1] = data.a.toString().split('').reverse()
        var [b_0, b_1] = data.b.toString().split('').reverse()
        return <>
            <h4>Q {key}</h4>
            <table key={key}>
                <tr>
                    <td>{ a_1 }</td><td>{ a_0 }</td>
                </tr>
                <tr>
                    <td>{ b_1 }</td><td>{ b_0 }</td>
                </tr>
                <tr style={{borderTop:'1px solid black'}}>
                    <td colspan='2'>&nbsp;</td>
                </tr>
            </table>
            <hr />
        </>
    }

    const formatAnswer = (data, key) => {
        var [a_0, a_1] = data.a.toString().split('').reverse()
        var [b_0, b_1] = data.b.toString().split('').reverse()
        var [c_0, c_1, c_2, c_3] = data.c.toString().split('').reverse()
        return <>
            <h4>Q {key}</h4>
            <table key={key}>
                <tr>
                    <td></td><td></td><td>{ a_1 }</td><td>{ a_0 }</td>
                </tr>
                <tr>
                    <td></td><td></td><td>{ b_1 }</td><td>{ b_0 }</td>
                </tr>
                <tr style={{borderTop:'1px solid black'}}>
                    <td>{ c_3 }</td><td>{ c_2 }</td><td>{ c_1 }</td><td>{ c_0 }</td>
                </tr>
            </table>
        </>
    }

    return <GeneratePageBase num_questions={num_questions} title={title} pattern={pattern}
            genQuestion={genQuestion} formatQuestion={formatQuestion} formatAnswer={formatAnswer} />
}

const GeneratePage4Div1 = () => {
    const num_questions = 10
    const title = '4 by 1 division'
    const pattern = '4_1_div'

    const randInt = (min, max) => {
        return Math.floor(Math.random() * (max - min) ) + min
    }

    const genQuestion = () => {
        var data = {}
        data.d = randInt(2,9) // divisor
        data.r = randInt(0,data.d) // remainder
        data.q = randInt(501,999) // quotient
        data.n = data.q * data.d + data.r // dividend
        return data
    }

    const formatQuestion = (data, key) => {
        var [d_0] = data.d.toString().split('').reverse()
        var [n_0, n_1, n_2, n_3] = data.n.toString().split('').reverse()
        return <>
            <h4>Q {key}</h4>
            <table key={key}>
                <tr>
                    <td></td><td colspan='5' style={{borderBottom:'1px solid black'}}></td>
                </tr>
                <tr>
                    <td style={{borderRight:'1px solid black'}}>{ d_0 }</td><td></td><td>{ n_3 }</td><td>{ n_2 }</td><td>{ n_1 }</td><td>{ n_0 }</td>
                </tr>
            </table>
            <hr />
        </>
    }

    const formatAnswer = (data, key) => {
        var [d_0] = data.d.toString().split('').reverse()
        var [r_0] = data.r.toString().split('').reverse()
        var [q_0, q_1, q_2] = data.q.toString().split('').reverse()
        var [a_0, a_1, a_2, a_3] = (data.n - data.r).toString().split('').reverse() 
        var [n_0, n_1, n_2, n_3] = data.n.toString().split('').reverse() 
        return <>
        <h4>Q {key}</h4>
        <table key={key}>
            <tr>
                <td></td><td colspan='2' style={{borderBottom:'1px solid black'}}></td><td style={{borderBottom:'1px solid black'}}>{ q_2 }</td><td style={{borderBottom:'1px solid black'}}>{ q_1 }</td><td style={{borderBottom:'1px solid black'}}>{ q_0 }</td>
            </tr>
            <tr>
                <td style={{borderRight:'1px solid black'}}>{ d_0 }</td><td></td><td>{ n_3 }</td><td>{ n_2 }</td><td>{ n_1 }</td><td>{ n_0 }</td>
            </tr>
            <tr>
                <td></td><td>-</td><td style={{borderBottom:'1px solid black'}}>{ a_3 }</td><td style={{borderBottom:'1px solid black'}}>{ a_2 }</td><td style={{borderBottom:'1px solid black'}}>{ a_1 }</td><td style={{borderBottom:'1px solid black'}}>{ a_0 }</td>
            </tr>
            <tr>
                <td></td><td></td><td></td><td></td><td></td><td>{ r_0 }</td>
            </tr>
        </table>
        <hr />
    </>
    }

    return <GeneratePageBase num_questions={num_questions} title={title} pattern={pattern}
            genQuestion={genQuestion} formatQuestion={formatQuestion} formatAnswer={formatAnswer} />
}

const GeneratePageQuad = () => {
    const num_questions = 10
    const title = 'Quadratic equations'
    const pattern = 'quad'

    const randInt = (min, max, exclude=[]) => {
        while (true) {
            var pick = Math.floor(Math.random() * (max - min) ) + min
            if (!exclude.includes(pick)) {
                return pick
            }
        }
    }

    const genQuestion = () => {
        var alpha = randInt(-10,10,[0])
        var beta = randInt(-10,10,[0,alpha]) // prevent repeated root
        var data = {
            alpha, beta,
            a: 1, b: -(alpha+beta), c: alpha * beta
        }
        return data
    }
    const signed = (num) => {
        if (num >= 0){
            return '+'+num
        } else {
            return '-'+(-num)
        }
    }
    const formatQuestion = (data, key) => {
        return <>
            <h4>Q {key}</h4>
                x<sup>2</sup>{ signed(data.b)}x{ signed(data.c)} = 0
        </>
    }

    const formatAnswer = (data, key) => {
        return <>
            <h4>Q {key}</h4>
            x<sup>2</sup>{ signed(data.b)}x{ signed(data.c)} = 0<br />
            (x {signed(-data.alpha)})(x {signed(-data.beta)}) = 0<br />
            x = {signed(data.alpha)} or x = {signed(data.beta)}
        </>
    }

    return <GeneratePageBase num_questions={num_questions} title={title} pattern={pattern}
            genQuestion={genQuestion} formatQuestion={formatQuestion} formatAnswer={formatAnswer} />
}


export const GeneratePage = (props) => {
    var params = props.match.params
    var worksheets = [
        {
            pattern: '4_3_mul',
            title: '4 x 3 multiplication',
            module: GeneratePage4x3
        },
        {
            pattern: '3_2_mul',
            title: '3 x 2 multiplication',
            module: GeneratePage3x2
        },
        {
            pattern: '2_2_mul',
            title: '2 x 2 multiplication',
            module: GeneratePage2x2
        },
        {
            pattern: '4_1_div',
            title: '4 by 1 division',
            module: GeneratePage4Div1
        },
        {
            pattern: 'quad',
            title: 'Quadratic equations',
            module: GeneratePageQuad
        },
    ]
    if (!params.pattern) {
        var links = []
        for (var sheet of worksheets){
            console.log(sheet)
            links.push(<li key={sheet.pattern}><Link to={generatePath(Router.routes.GENERATE_VIEW.path,{ pattern: sheet.pattern })}>{ sheet.title }</Link></li>)
        }
        return <>
         <h3>Practice worksheets</h3>
            <ul>
                { links }
            </ul>
        </>
    } else {
        var comp = worksheets.find(x => x.pattern === params.pattern)
        return React.createElement(comp.module);
    }
}

