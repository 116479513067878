import React from 'react'
// import { Store } from 'app'
import 'style/component/layout/element/footer.css'

export const Footer = () => {
	// const version = Store.useSelector(state => state.app.version)
	return <div className='footer'>
			Sohani Academy &copy; 2022<br />
			<span style={{ "fontFamily": "monospace", fontSize: "0.7em" }}>
			{/* API { version.apiVersion } { version.apiBuildDate }<br />
			Web { version.webVersion } { version.webBuildDate } */}
		</span>
	</div>
}