export class Logger {
    static log = (message) => {
        console.log(message)
    }

    static devLog = (message, data) => {
        console.groupCollapsed(message)
        if (process.env.NODE_ENV === 'development'){
            console.dir(data)
        }
        console.groupEnd()
    }

    static devStyleLog = (message, style, data) => {
        console.groupCollapsed(message, style)
        if ((process.env.REACT_APP_ENV_MODE === 'devlive') ||
            (process.env.REACT_APP_ENV_MODE === 'prodlive') ||
            (process.env.REACT_APP_ENV_MODE === 'dev')){
            console.dir(data)
        }
        console.groupEnd()
    }

}
