import React from 'react'
import { Link } from 'react-router-dom'
import { Router } from 'app'
import { DefaultLayout } from 'view/component/Layout'

export const ErrorPageBase = (props) => 
    <DefaultLayout>
        <div>
            <h2 className='text-monospace text-danger'>{ props.title }</h2>
            <div>{ props.descr }</div>
            <Link to={Router.routes.INDEX.path}>Back to main page</Link>
        </div>
    </DefaultLayout>