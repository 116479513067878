import React from 'react'
import { Redirect, generatePath } from 'react-router-dom'
import { Router } from 'app'
import { PradnyaSolutionsPage } from './Pradyna'
import { PrabhutvaSolutionsPage } from './Prabhutva'
import { IPMMegafinalSolutionsPage } from './IPM'
import { PrmoPracticePage } from './PRMO'
import { CreativeWorkshopPage } from './CreativeWorkshop'
import { MathFoundationsPage } from './MathFoundations'
import { NotFoundPage } from 'view/page/Error/NotFoundPage'

export const CoursePage = (props) => {
    var params = props.match.params
    var redirects = {
        'ipm2': 'ipmMegafinalSolutions',
        'ipm3': 'ipmMegafinalSolutions',
        'ipm4': 'ipmMegafinalSolutions',
        'ipm5': 'ipmMegafinalSolutions',
    }
    var courses = {
        'pradnyaSolutions': <PradnyaSolutionsPage />,
        'prabhutvaSolutions': <PrabhutvaSolutionsPage />,
        'ipmMegafinalSolutions': <IPMMegafinalSolutionsPage />,
        'prmoPractice': <PrmoPracticePage />,
        'creativeWorkshop': <CreativeWorkshopPage />,
        'mathFoundations': <MathFoundationsPage />,
    }
    if (params.course in redirects){
        return <Redirect to={generatePath(Router.routes.COURSE_VIEW.path,{ course: redirects[params.course] })} />
    } else if (params.course in courses){
        return courses[params.course]
    } else {
        return <NotFoundPage />
    }
}
